@import "../../../GlobalUtils/global-utils.scss";

.export-page {
	grid-row-start: 2;
	grid-row-end: 3;
	grid-column-start: 1;
	grid-column-end: 2;
	display: flex;
	flex-direction: column;
	align-items: center;

	overflow-y: hidden;

	@media (max-width: 768px) {
		overflow-y: visible;
	}
}

.export-page-header {
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 1;
	grid-column-end: 2;
	display: flex;
	align-items: center;
	padding: 25px;
	border-radius: 50px;
	margin-top: 15px;
	background-color: $color-primary-x-light;
	color: black;
}

.select-all {
	margin-right: 10px;
}
