@import "../../../GlobalUtils/global-utils.scss";

.day-controls {
	display: flex;
	@include font-size(18);
	margin: 0 auto;
	align-items: center;
	justify-content: space-between;
	gap: 15px;
	@include media-min-w(576px) {
		margin: 0;
	}
	@include media-min-w(768px) {
		margin: 0 auto;
	}
	@include media-min-w(1080px) {
		margin: 0;
	}

	button {
		width: 40px;
		height: 40px;
		padding: 0;
		&.go-back-day {
			background-image: url("../../assets/svg/chevron-blue-outline.svg");
			transform: rotate(-90deg);
		}
		&.go-forward-day {
			background-image: url("../../assets/svg/chevron-blue-outline.svg");
			transform: rotate(90deg);
		}
	}

	.date-calander {
		color: $color-text-contrast;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-family: $font-display;
		font-weight: 500;
		transition: font-size 0.3s, width 0.3s;
		@include font-size(16);
		@include media-min-w(480px) {
			@include font-size(19);
		}
	}
}
