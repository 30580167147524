@import "../../../GlobalUtils/global-utils.scss";

// Task / Group related details

.project-name,
.customer-name,
.task-due-date,
.task-metrics,
.project-metrics,
.description,
.status,
.integration {
	font-weight: 200;
	display: flex;
	flex-direction: row;
	flex: 1 1 100%;
	margin-bottom: 0;
}

.project-name,
.task-due-date {
	@include media-min-w(768px) {
		flex: 0 0 50%;
	}
}

.customer-name,
.project-metrics {
	@include media-min-w(768px) {
		flex: 0 0 50%;
	}
}

.project-metrics {
	justify-content: left;
}
.synced-time,
.running-time,
.health {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	margin-right: 12px;
}

.project-name,
.customer-name,
.task-due-date,
.synced-time,
.running-time,
.health {
	.icon {
		position: relative;
		flex: 0 0 22px;
		height: 22px;
		margin-right: 8px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 15px;
		opacity: 0.4;
		top: 2px;
	}
}

.project-name {
	.icon {
		background-image: url("../../assets/svg/task-icon-project-name-white-solid.svg");
	}
}
.customer-name {
	.icon {
		background-image: url("../../assets/svg/task-icon-customer-name-white-solid.svg");
	}
}

.task-due-date {
	.icon {
		background-image: url("../../assets/svg/calendar-white-solid.svg");
	}
}

.task-metrics,
.project-metrics {
	.synced-time {
		.icon {
			background-image: url("../../assets/svg/sync-white.svg");
			width: 22px;
		}
	}
	.running-time {
		.icon {
			background-image: url("../../assets/svg/task-icon-running-time-white.svg");
			width: 22px;
			background-size: 20px;
		}
	}
	.health {
		.icon {
			width: 10px;
			background-size: 17px;
			background-image: url("../../assets/svg/task-icon-health-white.svg");
		}
	}
}

.task-list-wrapper {
	.task-list-container {
		/*
--
--
DEFAULT
--
--
*/

		.task-list-item-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			position: relative;
			border-radius: 4px;
			padding: 15px;
			margin-bottom: 15px;
			transition: all 0.3s;

			background-color: $color-text;
			color: $color-text-contrast;

			@media screen and (min-width: 640px) and (min-height: 568px) {
				padding: 25px;
			}

			&.disabled {
				opacity: 0.6;
			}

			//   .highlight-task {
			//     z-index: 2;
			//     height: 100%;
			//     width: 100%;
			//     position: absolute;
			//     top: 0;
			//     left: 0;
			//   }

			.task-cta-wrapper {
				margin-right: 25px;
				flex: 1 0 100%;
				margin-bottom: 15px;
				overflow: hidden;
				transition: all 0.3s;
				button {
					&.task-button {
						width: 60px;
						height: 60px;
						padding: 0;
						text-align: center;
						opacity: 1;
						background-color: $color-primary;
						border-radius: 50%;
						background-size: 25px;
						background-position: center center;
						background-repeat: no-repeat;
						transition: all 0.3s;
						.task-button-label {
							display: block;
							@include font-size(19);
							transition: all 0.3s;
							top: 0;
							transform: translateY(0);
						}
						&.play {
							background-image: url("../../assets/svg/play-dark-purple-outline.svg");
							background-position: 18px center;
							.task-button-label {
								display: none;
							}
						}
						&.pause {
							background-image: url("../../assets/svg/pause-dark-purple.svg");
							.task-button-label {
								display: none;
							}
						}
						&.link {
							background-image: url("../../assets/svg/link-blue-outline.svg");
							@include interactive-states() {
								background-image: url("../../assets/svg/link-white-outline.svg");
							}
						}
						&.unlink {
							background-image: url("../../assets/svg/link-break-dark-purple-outline.svg");
						}
					}
				}
				@include media-min-w(480px) {
					flex: 0 1 auto;
					margin-bottom: 0px;
				}
			}
			.task-title-wrapper {
				margin-bottom: 16px;
			}

			.task-title-wrapper,
			.task-details-wrapper {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				position: relative;
				flex: 1 0 100%;
				padding: 0;

				@include media-min-w(480px) {
					flex: 1 1 calc(100% - 85px);
				}

				.task-detail {
					transition: all 0.3s;
					overflow: hidden;
					padding-right: 15px;
				}

				.task-name {
					display: flex;
					flex: 1 1 100%;
					padding-right: 0px;
					@include media-min-w(480px) {
						padding-right: 40px;
					}
					h2 {
						@include font-size(21);
						line-height: 1.3;
						font-weight: 400;
						@include media-min-w(568px) {
							@include font-size(25);
						}

						a {
							color: $color-text-contrast;
						}
					}
				}
			}

			.task-description-wrapper {
				overflow: hidden;
				transition: all 0.3s;
				@include media-min-w(480px) {
					margin-left: 85px;
				}

				.task-description-title {
					font-family: $font-display;
					font-weight: 500;
					display: flex;
					flex-direction: row;
					flex: 1 1 100%;
					@include font-size(20);
					margin-bottom: 10px;
					margin-top: 15px;
					padding-top: 15px;
					border-top: 1px solid rgba($color-text-contrast, 0.15);

					.icon {
						position: relative;
						flex: 0 0 22px;
						height: 22px;
						margin-right: 8px;
						background-repeat: no-repeat;
						background-position: center;
						background-size: 15px;
						opacity: 0.4;
						top: 5px;
						background-image: url("../../assets/svg/page-wide-white-solid.svg");
					}
				}
				.task-description-content {
					max-height: 220px;
					overflow-y: auto;
					padding-right: 15px;
					letter-spacing: 0.1px;
					margin-bottom: 10px;

					&::-webkit-scrollbar {
						width: 10px;
						background-color: rgba($color-text-contrast, 0.2);
					}
					&::-webkit-scrollbar-thumb {
						background-color: rgba($color-text-contrast, 0.4);
					}
				}
			}

			button {
				&.task-info,
				&.favourite,
				&.todo {
					height: 30px;
					width: 30px;
					padding: 0;
					border: 0;
					background-size: 20px;
				}

				&.task-info {
					position: absolute;
					right: 0;
					z-index: 2;
					background-image: url("../../assets/svg/chevron-blue-outline.svg");
					transform: rotate(180deg);
					background-position: center center;
					background-color: transparent;
					border: 1px solid transparent;
					&.expanded {
						transform: rotate(0deg);
					}
					&:hover {
						background-position: center 0px;
						&.expanded {
							background-position: center 0px;
						}
					}
					&:active,
					&:focus,
					&:active:focus {
						background-size: 25px;
					}
				}

				&.favourite {
					position: absolute;
					top: 15px;
					right: 15px;
					z-index: 2;
					@media screen and (min-width: 640px) and (min-height: 568px) {
						top: 25px;
						right: 25px;
					}
					&:active,
					&:focus,
					&:active:focus {
						background-size: 25px;
					}
					&.isFavourite {
						background-image: url("../../assets/svg/task-icon-favourites-blue-solid.svg");
					}

					&.notFavourite {
						background-image: url("../../assets/svg/task-icon-favourites-blue-outline.svg");
						&:hover {
							background-image: url("../../assets/svg/task-icon-favourites-blue-solid.svg");
						}
					}
				}

				&.todo {
					&.isTodo {
						//   border-color: darkgrey;
						//   background-color: darkgrey;
					}

					&.notTodo {
						//   border-color: lightgrey;
						//   background-color: lightgrey;
					}
				}
			}

			/*
--
--
TILE SIZES
--
--
*/

			&.size-small {
				@media screen and (min-width: 640px) and (min-height: 568px) {
					padding: 15px;
				}
				button {
					&.task-info {
						top: 0px;
						@include media-min-w(768px) {
							top: 0px;
						}
					}
					&.favourite {
						@media screen and (min-width: 640px) and (min-height: 568px) {
							top: 15px;
							right: 15px;
						}
					}
				}
				&.mode-link,
				&.mode-unlink {
					button {
						&.favourite {
							@media screen and (min-width: 640px) and (min-height: 568px) {
								top: 15px;
							}
						}
					}
				}
				.task-title-wrapper {
					margin-bottom: 8px;
				}
				.task-details-wrapper {
					.task-detail {
						&.md-show-hide {
							visibility: hidden;
							max-height: 0;
							@include media-min-w(768px) {
								max-height: 100px;
								visibility: visible;
								animation: fade-in 0.5s ease-in-out;
							}
						}
						&.lg-show-hide {
							visibility: hidden;
							max-height: 0;
						}
					}
				}
			}

			&.size-medium {
				button {
					&.task-info {
						top: 36px;
						@include media-min-w(768px) {
							top: 0px;
						}
					}
				}
				.task-details-wrapper {
					.task-detail {
						&.md-show-hide {
							max-height: 100px;
							visibility: visible;
							animation: fade-in 0.5s ease-in-out;
						}
						&.lg-show-hide {
							max-height: 0;
							visibility: hidden;
						}
					}
				}
			}

			&.size-large {
				button {
					&.task-info {
						top: 106px;
						@include media-min-w(768px) {
							top: 36px;
						}
					}
				}
				.task-details-wrapper {
					.task-detail {
						&.md-show-hide {
							visibility: visible;
							max-height: 100px;
							animation: fade-in 0.5s ease-in-out;
						}
						&.lg-show-hide {
							visibility: visible;
							max-height: 100px;
							animation: fade-in 0.5s ease-in-out;
						}
					}
				}
			}

			&.mode-minimised {
				&.size-small {
					.task-details-wrapper {
						// .project-name {
						//   flex: 0 0 100%;
						// }
						.task-detail {
							&.md-show-hide {
								max-height: 0;
								visibility: hidden;
								animation: fade-out 0.3s ease-in-out;
							}
							&.lg-show-hide {
								max-height: 0;
								visibility: hidden;
								animation: fade-out 0.3s ease-in-out;
							}
						}
					}
				}

				&.size-medium {
					.task-details-wrapper {
						.project-name {
							margin-bottom: 8px;
							@include media-min-w(768px) {
								margin-bottom: 0;
							}
						}
						.customer-name {
							padding-right: 40px;
						}
						.task-detail {
							&.md-show-hide {
								max-height: 100px;
								visibility: visible;
							}
							&.lg-show-hide {
								max-height: 0;
								visibility: hidden;
								animation: fade-out 0.3s ease-in-out;
							}
						}
					}
				}

				&.size-large {
					.task-details-wrapper {
						.project-name,
						.customer-name,
						.integration {
							margin-bottom: 8px;
						}
						.task-due-date {
							margin-bottom: 8px;
							@include media-min-w(768px) {
								margin-bottom: 0px;
							}
						}
						.project-metrics {
							margin-bottom: 0px;
						}
						.task-detail {
							&.md-show-hide {
								max-height: 100px;
								visibility: visible;
							}
							&.lg-show-hide {
								max-height: 100px;
								visibility: visible;
							}
						}
					}
				}

				.task-description-wrapper {
					max-height: 0px;
					visibility: hidden;
					animation: fade-out 0.3s ease-in-out;
				}
			}

			&.mode-expanded {
				.task-details-wrapper {
					.project-name,
					.customer-name,
					.task-due-date,
					.task-metrics,
					.project-metrics,
					.integration {
						margin-bottom: 8px;
					}
				}
				&.size-small,
				&.size-medium,
				&.size-large {
					.task-details-wrapper {
						.task-detail {
							&.md-show-hide {
								max-height: 100px;
								visibility: visible;
								animation: fade-in 0.3s ease-in-out;
							}
							&.lg-show-hide {
								max-height: 100px;
								visibility: visible;
								animation: fade-in 0.3s ease-in-out;
							}
						}
					}
					.task-description-wrapper {
						max-height: 500px;
						visibility: visible;
						animation: fade-in 0.3s ease-in-out;
					}
				}
				&.size-medium {
					.task-details-wrapper {
						.customer-name {
							padding-right: 40px;
						}
					}
				}
				&.size-large {
					.task-details-wrapper {
						.project-metrics {
							padding-right: 40px;
						}
					}
				}
			}

			/*
--
--
MODE LINK
--
--
*/

			&.mode-link,
			&.mode-unlink {
				@include media-min-w(480px) {
					display: block;
					position: relative;
				}

				.task-cta-wrapper {
					margin: -15px -15px 15px -15px;
					border: 0;
					flex: 1 0 100%;
					margin-bottom: 15px;
					border-radius: 4px 4px 0 0;
					width: auto;
					height: 70px;
					button {
						&.task-button {
							width: 100%;
							height: 70px;
							background-position: center calc(50% - 15px);
							border-radius: 0;
							.task-button-label {
								top: 50%;
								transform: translateY(15px);
							}
							@include media-min-w(480px) {
								width: 100%;
								height: 100%;
								border-radius: 4px 0 0 4px;
							}
						}
					}
					@include media-min-w(480px) {
						position: absolute;
						border-radius: 4px 0 0 4px;
						width: 70px;
						height: 100%;
						top: 0;
						left: 0;
						bottom: 0;
						margin: 0;
					}

					@include media-min-w(640px) {
						width: 80px;
					}
				}

				.task-name {
					padding-right: 40px;
				}

				button {
					&.favourite {
						top: 86px;
						@include media-min-w(480px) {
							top: 15px;
						}
						@media screen and (min-width: 640px) and (min-height: 568px) {
							top: 25px;
						}
					}
				}
				.task-title-wrapper,
				.task-details-wrapper {
					@include media-min-w(480px) {
						width: calc(100% - 80px);
						margin-left: 80px;
					}
				}
			}

			&.mode-link {
				.task-cta-wrapper {
					border-bottom: 2px solid $color-mid-purple;
					button {
						&.task-button {
							background-color: transparent;
							@include interactive-states() {
								background-color: $color-primary;
								.task-button-label {
									color: $color-primary-contrast-dark;
								}
							}
							.task-button-label {
								color: $color-text-contrast;
							}
						}
					}
					@include media-min-w(480px) {
						border: 0;
						border-right: 2px solid $color-mid-purple;
					}
				}
			}

			&.mode-unlink {
				.task-cta-wrapper {
					button {
						&.task-button {
							background-color: $color-secondary;
							@include interactive-states() {
								background-color: darken($color-secondary, 8%);
								.task-button-label {
									color: $color-secondary-contrast-dark;
								}
							}
							.task-button-label {
								color: $color-secondary-contrast-dark;
							}
						}
					}
				}
			}
		}
	}
}
