@import "../../../GlobalStyles/global-styles.scss";

input[type="text"] {
	font: "Dosis", sans-serif;
}

.task-name,
.group-name {
	//   font-weight: 900;
	.editable-label {
		label {
			display: block;
			background: rgba(255, 255, 255, 0.1);
			padding: 2px 45px 6px 16px;
			border-radius: 20px;
			line-height: 1;
			background-image: url("../../assets/svg/pencil-white-outline.svg");
			background-position: right 12px center;
			background-repeat: no-repeat;
			background-size: 20px;
			@include interactive-states() {
				cursor: pointer;
			}
		}

		.editable-label-input-wrapper {
			display: block;
			input {
				&.editable-label-input-box {
					font-family: $font-body;
					font-weight: 900;
					display: block;
					position: relative;
					width: auto;
					border-radius: 40px 0px 0px 40px;
					border: 0px solid $color-text-contrast;
					background-color: $color-text-contrast;
					color: $color-text;
					@include font-size(18);
					font-weight: 400;
					height: 40px;
					padding: 4px 15px;
					box-sizing: border-box;
					margin: 0;
					@include interactive-states() {
						outline: 0;
						box-shadow: none;
					}
				}
			}
			button {
				&.save-edit-button {
					display: block;
					position: absolute;
					background-size: 16px;
					background-color: $color-tertiary;
					background-image: url("../../assets/svg/tick-white-outline.svg");
					margin: 0;
					top: 0px;
					right: 0px;
					border-radius: 0 40px 40px 0;
					width: 50px;
				}

				//this is just a copy of save-edit-button to stop it breaking.
				&.cancel-edit-button {
					display: block;
					position: absolute;
					background-size: 16px;
					background-color: red; // TODO: Change to actual cancel colour
					// TODO: Replace with X
					//background-image: url("../../assets/svg/tick-white-outline.svg");
					margin: 0;
					top: 0px;
					right: 0px;
					border-radius: 0 40px 40px 0;
					width: 50px;

					color: white;
					font-size: 15pt;
				}
			}
		}
	}
}
