/*
--
Theme - Mixins
--
*/

@use "sass:math";

$rem-base: 16;
$rem-unit: math.div(1rem, $rem-base);

// Ratio of character width to height
// Based on width of the '0' character set in 100px Dosis 'medium' font
$font-ratio: 0.431;
// $font-ratio: 0.5385;

/* Mixins - Media Queries */
@mixin media-min-w($min) {
	@media screen and (min-width: $min) {
		@content;
	}
}

@mixin media-min-h($min) {
	@media screen and (min-height: $min) {
		@content;
	}
}

@mixin media-max-w($max) {
	@media screen and (max-width: $max) {
		@content;
	}
}

@mixin media-max-h($max) {
	@media screen and (max-height: $max) {
		@content;
	}
}

/* Mixins - Fonts */
@mixin font-size($px) {
	font-size: $px * $rem-unit;
}

@mixin mono-font-size($px) {
	@include font-size($px); // Convert font size to rem units
	$width: $px * $font-ratio * 1.3; // 30% space buffer allows for space either side of character
	width: $width * $rem-unit; // Convert character width allowance to rem units
}

@mixin font-style($weight, $style) {
	font-weight: $weight;
	@if $style == "italic" {
		font-style: italic;
	} @else {
		font-style: normal;
	}
}

/* Mixins - Animation & Keyframes */

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-o-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@mixin animation($animation) {
	-webkit-animation: $animation;
	-moz-animation: $animation;
	-ms-animation: $animation;
	-o-animation: $animation;
	animation: $animation;
}

/* Mixins - Transform */
@mixin transform($args1: null, $args2: null, $args3: null, $args4: null) {
	transform: $args1 $args2 $args3 $args4;
	-webkit-transform: $args1 $args2 $args3 $args4;
	-moz-transform: $args1 $args2 $args3 $args4;
	-o-transform: $args1 $args2 $args3 $args4;
}

/* Mixins - Transition */
@mixin transition($args1: null, $args2: null, $args3: null) {
	transition: $args1, $args2, $args3;
	-webkit-transition: $args1, $args2, $args3;
	-moz-transition: $args1, $args2, $args3;
	-o-transition: $args1, $args2, $args3;
}

/* Mixins - Border Radius */
@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

/* Mixins - Interactive */
@mixin interactive-states {
	&:hover,
	&:active,
	&:focus,
	&:active:focus,
	&:not(:disabled):not(.disabled):active {
		@content;
	}
}

/* Flex Box */
@mixin flex-display {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin flex($value) {
	-webkit-box: $value;
	-moz-box: $value;
	-webkit-flex: $value;
	-ms-flex: $value;
	flex: $value;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
		-moz-box-direction: reverse;
		-moz-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
		-moz-box-direction: normal;
		-moz-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
		-moz-box-direction: reverse;
		-moz-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
		-moz-box-direction: normal;
		-moz-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin flex-justify($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-moz-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-moz-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $value;
		-moz-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	justify-content: $value;
}

@mixin flex-align-items($align) {
	-webkit-align-items: $align;
	-moz-align-items: $align;
	-ms-align-items: $align;
	align-items: $align;
}

@mixin flex-grow($int: 1) {
	-webkit-box-flex: $int;
	-moz-box-flex: $int;
	-webkit-flex-grow: $int;
	-ms-flex: $int;
	flex-grow: $int;
}
