@import "../../../../GlobalUtils/global-utils.scss";

.click-off-cancel {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 99;
}

.halo-time-label-wrapper {
	.halo-time-label-editing {
		position: absolute;
		background-color: $color-text-contrast;
		color: $color-text;

		.hour-input-wrapper {
			display: inline-block;
			width: 30px;
			margin-left: 8px;
			margin-right: 5px;
			.hour-input {
				width: 30px;
			}
		}

		.minute-input-wrapper {
			display: inline-block;
			width: 30px;
			margin-left: 5px;
			margin-right: 5px;

			.minute-input {
				width: 30px;
			}
		}

		.am-pm-toggle {
			display: inline-block;
			margin-left: 5px;

			.am-button {
				background-color: lightgray;
			}
			.am-button:disabled {
				color: $color-text;
				background-color: darkgray;
			}

			.pm-button {
				background-color: lightgray;
			}
			.pm-button:disabled {
				color: $color-text;
				background-color: darkgray;
			}
		}
	}
}
