@import "../../../GlobalUtils/global-utils.scss";

.group-summary {
	position: relative;
}

.group-summary-contrast {
	.time-entry-summary {
		color: $color-text-contrast;

		.time-wrapper {
			color: $color-text;
			.time-wrapper {
				.time-in-hours {
					.is-running-icon {
						background-image: url("../../../Components/assets/svg/task-icon-running-time-dark-purple.svg");
					}
				}
			}
		}

		.comment-wrapper {
			.time-entry-comment {
				color: $color-text-contrast;
			}
		}
	}
}
