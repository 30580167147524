@import "../../../GlobalUtils/global-utils.scss";

.export-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	max-width: 800px;
	padding: 0 15px;
	width: 100%;
	margin: 0 auto;
	overflow-y: scroll;
}

.date-separator-wrapper + .task-export-record-wrapper {
	margin-top: 20px;
	padding-top: 0px;
	border-top: 0;
}

.task-export-record-wrapper {
	position: relative;
	display: flex;
	margin-top: 20px;
	padding-top: 20px;
	border-top: 1px solid rgba($color-text, 0.15);
	width: 100%;
	transition: padding 0.3s;

	&:last-of-type {
		border-bottom: 0;
		margin-bottom: 30px;
	}

	.checkbox-container {
		margin-right: 20px;
	}

	.task-details-wrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		flex: 1 1 100%;
		padding: 0;

		@include media-min-w(480px) {
			flex: 1 1 calc(100% - 85px);
		}

		.task-detail {
			margin-bottom: 8px;
			transition: all 0.3s;
			overflow: hidden;
			&:last-of-type {
				margin-right: 40px;
			}
		}

		.task-name {
			display: flex;
			flex: 1 1 100%;
			margin-bottom: 8px;
			margin-right: 0px;
			@include media-min-w(480px) {
				margin-right: 40px;
			}
			h2 {
				@include font-size(21);
				line-height: 1.3;
				font-weight: 400;
				@include media-min-w(568px) {
					@include font-size(25);
				}
			}
		}
		.task-description,
		.task-metrics,
		.task-project-name,
		.task-customer-name,
		.task-integration,
		.comments-heading-wrapper {
			font-weight: 400;
			display: flex;
			flex-direction: row;
			flex: 1 1 100%;
			//   @include font-size(16);
		}

		.task-project-name,
		.task-customer-name,
		.comments-heading-wrapper {
			.icon {
				position: relative;
				flex: 0 0 15px;
				height: 15px;
				margin-right: 8px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: contain;
				opacity: 0.4;
				top: 6px;
			}
		}

		.task-project-name {
			.icon {
				background-image: url("../../assets/svg/task-icon-project-name-blue-solid.svg");
			}
		}
		.task-customer-name {
			.icon {
				background-image: url("../../assets/svg/task-icon-customer-name-blue-solid.svg");
			}
		}
		.comments-heading-wrapper {
			font-weight: 500;
			margin-bottom: 5px;
			.icon {
				background-image: url("../../assets/svg/note-blue-solid.svg");
			}
		}

		.task-total-time {
			font-family: $font-display;
			color: $color-primary;

			.time,
			.time-indicator {
				font-weight: 700;
			}

			.time {
				@include font-size(24);
			}
			.time-indicator {
				font-family: $font-display;
				display: inline-block;
				@include mono-font-size(24);
			}
			.time-unit-indicator {
				font-family: $font-display;
				margin-left: 2px;
				font-weight: 400;
				display: inline-block;
				@include font-size(18);
			}

			@include media-min-w(480px) {
				width: 50px;
				text-align: right;
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.task-record-comments {
			//   margin-top: 10px;
			margin-bottom: 6px;
			width: 100%;
			//   @include font-size(18);

			p {
				width: 100%;
				overflow: auto;
				max-height: 400px;
			}
		}
	}

	&.is-playing {
		.task-name {
			@include media-min-w(480px) {
				margin-right: 145px;
			}
		}
		.task-total-time {
			.time-unit-indicator {
				margin-left: 2px;
				margin-right: 4px;
				font-weight: 500;
				@include font-size(18);
			}
			@include media-min-w(480px) {
				width: 140px;
			}
		}
	}
	&.exporting {
		// opacity: 0.4;
		// padding: 15px;
		border-top: 1px solid transparent;

		.task-total-time {
			color: rgba($color-text, 0.75);
		}

		.task-project-name {
			.icon {
				background-image: url("../../assets/svg/task-icon-project-name-dark-purple-solid.svg");
			}
		}
		.task-customer-name {
			.icon {
				background-image: url("../../assets/svg/task-icon-customer-name-dark-purple-solid.svg");
			}
		}
		.comments-heading-wrapper {
			.icon {
				background-image: url("../../assets/svg/note-dark-purple-solid.svg");
			}
		}
	}
	.spinner-wrapper {
		display: grid;
		align-content: center;
		justify-items: center;
		position: absolute;
		animation: fade-in 0.5s ease-in-out;
		height: calc(100% + 10px);
		width: calc(100% + 20px);
		background: rgba($color-secondary, 0.8);
		top: -5px;
		left: -10px;
		border-radius: 4px;
		z-index: 2;
		text-align: center;
		.spinner-container {
			margin: auto;
		}
		.spinner {
			width: 20px;
			height: 20px;
			margin: 0 auto 10px auto;
			border-color: $color-primary-contrast;
		}
		.spinner-label {
			font-weight: 500;
			@include font-size(16);
		}
	}
}

.exported-wrapper {
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
	position: absolute;
	animation: fade-in 0.5s ease-in-out;
	height: calc(100% + 10px);
	width: calc(100% + 20px);
	background: rgba($color-tertiary, 0.8);
	top: -5px;
	left: -10px;
	border-radius: 4px;
	z-index: 2;
	text-align: center;
	.exported-label {
		font-weight: 500;
		@include font-size(16);
	}
}

.warning {
	&.current-entry-warning {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		flex: 1 1 auto;
		background-color: rgba($color-secondary, 0.3);
		// border: 1px solid rgba($color-secondary, 0.4);
		color: $color-text;
		font-weight: 500;
		border-radius: 15px;
		padding: 10px 15px;
		margin: 10px 0;
	}
	.warning-icon {
		display: inline-block;
		position: relative;
		flex: 0 0 100%;
		height: 22px;
		width: 22px;
		background-image: url("../../assets/svg/warning-orange-solid.svg");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		margin-bottom: 5px;
		@include media-min-w(480px) {
			margin-right: 10px;

			flex: 0 0 22px;
			margin-bottom: 0px;
		}
	}
	.warning-label {
		text-align: center;
		flex: 1 0 100%;
		margin-bottom: 8px;
		@include media-min-w(480px) {
			text-align: left;
			flex: 1 1 auto;
			margin-bottom: 0px;
		}
	}
	button {
		flex: 0 0 auto;
		@include media-min-w(480px) {
			margin: 2px 0 2px 15px;
		}
		.icon {
			background-image: url("../../assets/svg/pause-orange.svg");
			transition: all 0.3s;
		}
		@include interactive-states() {
			.icon {
				background-image: url("../../assets/svg/pause-white.svg");
			}
		}
	}
}
