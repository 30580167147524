@import "../../../GlobalUtils/global-utils.scss";

$gi-icon-1: "../../assets/svg/group-indicator-icon-1.svg";
$gi-color-1: #c252dd;

$gi-icon-2: "../../assets/svg/group-indicator-icon-2.svg";
$gi-color-2: #7f8bf4;

$gi-icon-3: "../../assets/svg/group-indicator-icon-3.svg";
$gi-color-3: rebeccapurple;

$gi-icon-4: "../../assets/svg/group-indicator-icon-4.svg";
$gi-color-4: #31569b;

$gi-icon-5: "../../assets/svg/group-indicator-icon-5.svg";
$gi-color-5: #993372;

/* DEFAULTS */

.group-indicator {
	&.time-unit-group-indicator {
		border: 2px solid #fff;
		background-color: transparent;
	}
	&.task-group-indicator {
		background-color: $color-primary-contrast;
		border: 2px solid;
		border-color: #fff;
		background-image: url("../../assets/svg/link-connector-blue.svg");
	}
	&.playhead-group-indicator {
		padding: 0;
		height: 25px;
		width: 25px;
		border-radius: 50px;
		border: 2px solid #fff;
		z-index: 2;
		background-repeat: no-repeat;
		background-position: center center;
		margin-right: 10px;
	}

	&.group-indicator-no-icon {
		display: none;
	}
}

/* GROUPS */

.group-indicator {
	/* Integrated Classes (Icon + Colour) */

	&.group-indicator-1,
	&.group-indicator-2,
	&.group-indicator-3,
	&.group-indicator-4,
	&.group-indicator-5,
	&.group-indicator-6,
	&.group-indicator-7,
	&.group-indicator-8,
	&.group-indicator-9,
	&.group-indicator-10,
	&.group-indicator-11,
	&.group-indicator-12,
	&.group-indicator-13,
	&.group-indicator-14,
	&.group-indicator-15,
	&.group-indicator-16,
	&.group-indicator-17,
	&.group-indicator-18,
	&.group-indicator-19,
	&.group-indicator-20 {
		&.task-group-indicator {
			display: block;
		}
	}

	&.group-indicator-1 {
		background-image: url($gi-icon-1);
		background-color: $gi-color-1;
		@include interactive-states() {
			background-color: darken($gi-color-1, 10%);
		}
	}

	&.group-indicator-2 {
		background-image: url($gi-icon-2);
		background-color: $gi-color-2;
		@include interactive-states() {
			background-color: darken($gi-color-2, 10%);
		}
	}

	&.group-indicator-3 {
		background-image: url($gi-icon-3);
		background-color: $gi-color-3;
		@include interactive-states() {
			background-color: darken($gi-color-3, 10%);
		}
	}

	&.group-indicator-4 {
		background-image: url($gi-icon-4);
		background-color: $gi-color-4;
		@include interactive-states() {
			background-color: darken($gi-color-4, 10%);
		}
	}

	&.group-indicator-5 {
		background-image: url($gi-icon-5);
		background-color: $gi-color-5;
		@include interactive-states() {
			background-color: darken($gi-color-5, 10%);
		}
	}

	/* 
	--
	--
	Seperated Classes (Icon + Colour) ??TO DELETE??
	--
	--
	*/

	&.group-indicator-icon-1 {
		background-image: url("../../assets/svg/group-indicator-icon-1.svg");
	}
	&.group-indicator-icon-2 {
		background-image: url("../../assets/svg/group-indicator-icon-2.svg");
	}
	&.group-indicator-icon-3 {
		background-image: url("../../assets/svg/group-indicator-icon-3.svg");
	}
	&.group-indicator-icon-4 {
		background-image: url("../../assets/svg/group-indicator-icon-4.svg");
	}
	&.group-indicator-icon-5 {
		background-image: url("../../assets/svg/group-indicator-icon-5.svg");
	}
	&.group-indicator-colour-1 {
		background-color: #c252dd;
		@include interactive-states() {
			background-color: darken(#c252dd, 10%);
		}

		&.task-group-indicator {
			display: block;
		}
	}
	&.group-indicator-colour-2 {
		background-color: #7f8bf4;
		@include interactive-states() {
			background-color: darken(#7f8bf4, 10%);
		}
		&.task-group-indicator {
			display: block;
		}
	}
	&.group-indicator-colour-3 {
		background-color: rebeccapurple;
		@include interactive-states() {
			background-color: darken(rebeccapurple, 10%);
		}
		&.task-group-indicator {
			display: block;
		}
	}
	&.group-indicator-colour-4 {
		background-color: #31569b;
		@include interactive-states() {
			background-color: darken(#31569b, 10%);
		}
		&.task-group-indicator {
			display: block;
		}
	}
	&.group-indicator-colour-5 {
		background-color: #993372;
		@include interactive-states() {
			background-color: darken(#993372, 10%);
		}
		&.task-group-indicator {
			display: block;
		}
	}
}
