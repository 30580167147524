@import "../../../GlobalUtils/global-utils.scss";

.alert-box {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: 99999;

	&.display-block {
		display: block;
		.alert-box-main {
			@include transform(translate(-50%, -50%), scale(1));
		}
	}

	&.display-none {
		display: none;
		.alert-box-main {
			@include transform(translate(-50%, -50%), scale(0.8));
		}
	}

	.alert-box-main-wrapper {
		position: absolute;
		background: transparent;
		top: calc(50% + 20px);
		left: 50%;
		transform: translate(-50%, -50%);
		transition: transform 0.3s, top 0.3s;
		z-index: 999;
		@include border-radius(15px);

		.alert-box-main-container {
			@include flex-display();
			@include flex-direction(column);
			position: relative;
			flex: 1 0 auto;
			width: 100%;
			height: 100%;
			background-color: $color-mid-purple;
			color:$color-text-contrast;

			.alert-box-content-wrapper {
				padding: 20px;
			}
		}
	}

	.click-off-close {
		width: 100%;
		height: 100%;
	}

	.close-button {
		position: absolute;
		background-image: url("../../assets/svg/close-white-outline.svg");
		z-index: 3;
		right: 0;
		top: 15px;
		right: 15px;
		background-size: 16px;
		@include transform(rotate(0deg));
		@include interactive-states() {
			@include transform(rotate(180deg));
		}
	}

	.save-button {
		position: absolute;
		right: 2%;
		bottom: 2%;
		width: 80px;
		height: 50px;
		background-color: green;
		font-size: 18pt;
	}
}
