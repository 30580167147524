/* Safari 4.0 - 8.0 */
@-webkit-keyframes fade-in {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		max-height: 500px;
		opacity: 1;
	}
}

/* Standard syntax */
@keyframes fade-in {
	from {
		// max-height: 0;
		opacity: 0;
	}
	to {
		// max-height: 500px;
		opacity: 1;
	}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes fade-out {
	from {
		opacity: 1;
		// max-height: 500px;
	}
	to {
		opacity: 0;
		// max-height: 0px;
	}
}

/* Standard syntax */
@keyframes fade-out {
	from {
		opacity: 1;
		// max-height: 500px;
	}
	to {
		opacity: 0;
		// max-height: 0px;
	}
}
