/*
--
Global - Reset
--
*/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
