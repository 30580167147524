@import "../../../GlobalUtils/global-utils.scss";

.task-details-panel {
	//   height: 50%;
	flex: 1 1 auto;
	text-align: left;
	width: 100%;
	min-height: 75px;
	overflow: auto;
	.task-header-wrapper {
	}
	.task-details-wrapper {
	}
}

.task-details-panel,
.comments-panel {
	overflow-y: auto;
}

// .comments-panel {
//   border-top: 1px solid rgba($color-text, 0.2);
// }

.comments-panel {
	background-color: $color-mid-purple;
	border-top: 2px solid $color-text;
	.group-comments-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		.add-comment-wrapper {
			margin-top: auto;
		}
	}
}

/** DEFUNCT ? **/

.task-details-wrapper {
	@include flex(1 0 auto);
	.task-details-container {
		.task-sticky {
			position: relative;
			text-align: center;
			// DO STICKY STUFF HERE. Yes that phrasing was intentional.
			.task-name-label {
				display: inline-block;
				padding: 15px;
				padding-top: 30px;
				color: black;
				text-align: center;
			}

			.select-task {
				display: inline-block;
				// height: 50px;
				width: 200px;
				padding: 15px;
				margin: 10px;

				background-color: grey;
				color: black;
				border-radius: 20px;

				font-size: 20pt;
				font-weight: 600;
			}
		}

		.divider {
			margin-left: 20px;
			margin-right: 20px;
			border-bottom: 1px solid black;
		}

		.task-info {
			display: inline-block;

			.task-status {
				display: inline-block;
				padding-right: 10px;
			}
			.task-due-date {
				display: inline-block;
			}

			.task-info-section {
				padding-top: 5px;
			}
		}
	}
}
