@import "../../GlobalUtils/global-utils.scss";

#dashboard-wrapper {
	//   grid-row-start: 2;
	//   grid-row-end: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	//   min-height: 408px;
	padding: 10px 0 25px 0;
	@include transition(height 0.3s);

	@media screen and (min-height: 768px) {
		padding: 30px 0 50px 0;
	}

	//   @media screen and (max-width: 767px) and (min-height: 767px) {
	//     height: calc(100vh - 160px);
	//   }

	//   @media screen and (max-width: 767px) and (min-height: 768px) {
	//     height: calc(100vh - 350px);
	//   }

	//   @media screen and (max-width: 767px) and (min-height: 768px) {
	//     height: calc(50vh - 85px);
	//   }

	@media screen and (min-width: 768px) {
		grid-row-start: 2;
		grid-row-end: 3;
	}
}

.edit-time-entry {
	background-color: $color-secondary;
}
