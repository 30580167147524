@import "../../../GlobalUtils/global-utils.scss";

// ONLY RELEVANT With search-control-wrapper toggle button

#search-controls-wrapper {
	flex: 0 0 auto;
	height: auto;
	@include transition(height 0.3s);
	overflow-y: hidden;
	overflow-x: auto;
	background-color: $color-text;
	box-shadow: 0 0px 10px 8px rgba(darken($color-text, 15%), 0.3);
	z-index: 2;

	.search-controls-container {
		@include flex-display();
		@include flex-direction(row);
		flex-wrap: nowrap;
		overflow-x: auto;
		padding: 10px 0;
		max-width: 1080px;
		margin: 0 auto;
		justify-content: left;

		@include media-min-w(640px) {
			justify-content: center;
		}

		@media screen and (min-width: 640px) and (min-height: 568px) {
			padding: 15px 0;
		}

		::-webkit-scrollbar {
			background-color: $color-mid-purple;
		}
		::-webkit-scrollbar-button {
			display: none;
		}
		::-webkit-scrollbar-track {
			background-color: $color-mid-purple;
		}
		::-webkit-scrollbar-track-piece {
			background-color: lighten($color-mid-purple, 10%);
		}
		::-webkit-scrollbar-thumb {
			/* 5 */
		}
		::-webkit-scrollbar-corner {
			/* 6 */
		}
		::-webkit-resizer {
			/* 7 */
		}

		/* SEARCH INPUT DEFAULT */
		.search-input-wrapper {
			display: inline-block;
			position: relative;
			flex-shrink: 0;
			min-height: 45px;
			width: 130px;
			flex: 1 0 130px;
			overflow: hidden;
			margin-left: 20px;
			@include transition(all 0.3s);

			@include media-min-w(640px) {
				flex: 0 0 130px;
			}

			@media screen and (min-width: 1080px) and (min-height: 768px) {
				min-height: 50px;
			}

			.search-input-container {
				@include flex-display();
				position: relative;
				@include flex-justify(space-between);
				@include flex-align-items(center);
				width: 100%;
				overflow: hidden;
				height: 100%;
				// padding-left: 40px;

				@include transition(all 0.3s);
				input {
					@include transition(all 0.3s);
					@include interactive-states() {
						outline: 0;
					}
					&.search-input {
						width: 100%;
						flex: 1 1 100%;
						display: block;
						position: relative;
						color: rgba($color-text-contrast, 0.4);
						background-color: transparent;
						border: 0;
						height: 100%;
						box-sizing: border-box;
						overflow: visible;
						padding-left: 4px;
						padding-right: 4px;
						border-bottom: 1px solid rgba($color-text-contrast, 0.4);
						&::selection {
							color: $color-primary-contrast;
							background-color: $color-primary;
						}
						&::-webkit-input-placeholder {
							/* Edge */
							color: rgba($color-text-contrast, 0.4);
						}

						&:-ms-input-placeholder {
							/* Internet Explorer 10-11 */
							color: rgba($color-text-contrast, 0.4);
						}

						&::placeholder {
							color: rgba($color-text-contrast, 0.4);
						}
					}
				}
				button {
					opacity: 1;
					height: 100%;
					&.search-clear {
						display: none;
						position: absolute;
						background-color: transparent;
						border: 0;
						z-index: 2;
						padding: 0;
						left: 0px;
						top: 0;
						width: 40px;
						opacity: 0.75;
						background-image: url("../../assets/svg/close-white-outline.svg");
						background-size: 10px;
						background-position: center center;
						transform-origin: 50% 50%;
						@include transform(rotate(0deg));
						@include transition(all 0.3s);
						@include interactive-states() {
							@include transform(rotate(180deg));
							background-position: center center;
							transform-origin: 50% 50%;
						}
					}
					&.search-submit {
						box-sizing: border-box;
						padding: 0;
						line-height: 40px;
						background-color: transparent;
						background-image: url("../../assets/svg/search-white-outline.svg");
						background-repeat: no-repeat;
						background-position: 12px center;
						background-size: 20px;
						border: 0;
						border-bottom: 1px solid $color-text-contrast;
						flex: 0 0 35px;
						width: 35px;
						padding: 0;
						overflow: hidden;
						opacity: 0.4;
					}
				}
			}
		}
	}

	/* SEARCH INPUT OPEN */
	&.mode-search {
		&.search-emphasis {
			.search-controls-container {
				.search-input-wrapper {
					.search-input-container {
						button {
							&.search-submit {
								background-image: url("../../assets/svg/search-blue-outline.svg");
							}
						}
					}
				}
			}
		}
		.search-controls-container {
			.search-input-wrapper {
				widows: 75%;
				flex: 1 0 75%;
				@include media-min-w(420px) {
					flex: 0 0 300px;
				}
				@include media-min-w(640px) {
					flex: 0 1 300px;
				}
				@include media-min-w(768px) {
					//   flex: 1 0 auto;
				}
				.search-input-container {
					input {
						&.search-input {
							color: $color-text-contrast;
							border-bottom: 1px solid rgba($color-text-contrast, 1);
						}
					}
					button {
						&.search-submit {
							opacity: 1;
						}
					}
				}
			}
		}
		.task-filter-wrapper {
			.task-filter-container {
				button {
					&.filter {
						&.filter-search {
							width: 0;
							visibility: hidden;
							margin: 0;
							overflow: hidden;
						}
					}
				}
			}
		}
	}

	/* FILTER BUTTONS */
	.task-filter-wrapper {
		flex: 1 0 auto;
		flex-wrap: nowrap;
		display: inline-block;
		min-height: 45px;
		overflow: hidden;
		@include media-min-w(420px) {
			flex: 0 0 auto;
		}
		@media screen and (min-width: 1080px) and (min-height: 768px) {
			min-height: 50px;
		}
		.task-filter-container {
			@include flex-display();
			@include flex-direction(row);
			flex-wrap: nowrap;
			flex: 1 1 100%;
			height: 100%;
			@include flex-justify(space-between);

			button {
				display: block;
				flex: 0 0 auto;
				box-sizing: border-box;
				padding: 0;
				@include transition(all 0.3s);
				&.filter {
					height: 100%;
					width: auto;
					max-width: 100px;
					overflow: visible;
					color: rgba($color-text-contrast, 0.4);
					border: 0;
					background-color: transparent;
					margin-left: 25px;
					//   @include border-radius(40px);
					@include interactive-states() {
						color: rgba($color-text-contrast, 1);
						.icon {
							opacity: 1;
							&:before {
								color: rgba($color-text-contrast, 1);
							}
						}
					}

					@include media-min-w(568px) {
						margin-left: 30px;
					}
					@media screen and (min-width: 768px) {
						margin-left: 40px;
					}

					&.active {
						color: $color-primary-contrast;
						@include interactive-states() {
							color: $color-primary-contrast;
						}

						.icon {
							opacity: 1;

							&:before {
								color: rgba($color-text-contrast, 1);
							}
						}
					}
					&.disabled,
					&:disabled {
						opacity: 0.15;
						color: $color-text-contrast;
						border: 1px solid $color-text-contrast;
						background-color: $color-text-contrast;
						cursor: not-allowed;
						@include interactive-states() {
							opacity: 0.15;
							color: $color-text-contrast;
							background-color: $color-text;
						}
					}

					&:last-of-type {
						margin-right: 20px;
					}
					.label {
						display: none;
						margin: 0 auto;
						@include font-size(16);
						line-height: 1;
						transform: translateY(1px);
						@media screen and (min-width: 568px) {
							display: block;
						}
					}
					.icon {
						width: 30px;
						height: 30px;
						position: relative;
						display: block;
						margin: 0 auto;
						background-size: 20px;
						background-position: center;
						background-repeat: no-repeat;
						opacity: 0.4;
						transition: all 0.3s;

						&:before {
							display: block;
							position: absolute;
							content: "";
							font-family: "Font Awesome 5 Pro";
							font-weight: 900;
							@include font-size(20);
							top: 50%;
							left: 50%;
							@include transform(translate(-50%, -50%));
							@include transition(all 0.3s);
							color: rgba($color-text-contrast, 1);
						}
					}

					/* Individual Filter Buttons */
					&.filter-search {
						display: none;
						visibility: 1;
						width: auto;
					}

					&.filter-favourites {
						.icon {
							background-image: url("../../assets/svg/task-icon-favourites-white-solid.svg");
						}
					}

					&.filter-recent {
						.icon {
							background-image: url("../../assets/svg/task-icon-history-white-outline.svg");
						}
					}

					&.filter-most-used {
						.icon {
							background-image: url("../../assets/svg/task-icon-most-used-white-outline.svg");
						}
					}

					&.filter-due-date {
						.icon {
							background-image: url("../../assets/svg/calendar-white-solid.svg");
						}
					}

					&.filter-new {
						.icon {
							background-image: url("../../assets/svg/task-icon-new-white-solid.svg");
						}
					}

					//   &.filter-to-do {
					//     .icon {
					//       &::before {
					//         content: "\f0ae";
					//       }
					//     }
					//   }
				}
			}
		}
	}

	.search-description-wrapper {
		// margin-top: 15px;
	}
}
