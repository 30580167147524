@import "../../../GlobalUtils/global-utils.scss";
@import "../../TreeVars.scss";

.timeline-content-wrapper {
	.timeline-content-container {
		.time-units-wrapper {
			height: 100%;
			width: 100%;
			display: block;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;

			.insert-new-entry {
				position: absolute;
				height: 35px;
				width: 35px;
				left: 50%;
				margin-left: -20px;

				&.top{
					
				}
				&.bottom{
					
				}

				svg{
					height: 40px;
					width: 40px;
				}
	
				circle {
					fill: transparent;
					stroke: $color-text-contrast;
					stroke-width: 4;
				}
				
				.dashed-circle {
					stroke-dasharray: 12, 20;
				}
				
	
				.insert-new-entry-content{
					margin-top: -28px;
					margin-left: 20px;
					font-size: 70pt;
					font-weight: 300;
					color: $color-secondary;
				}

			}

			.time-unit-wrapper {
				position: absolute;
				left: 50%;
				width: calc(100% - 155px);
				max-width: 250px;
				pointer-events: none;
				@include media-min-w(640px) {
					max-width: 350px;
				}

				.time-unit {
					display: block;
					position: relative;
					left: -50%;
					height: 100%;
					pointer-events: all;

					.time-entry-block {
						width: 100%;
						height: 100%;
						display: block;
						@include border-radius(4px 16px 4px 16px);
						border: 2px solid $color-mid-purple;
					}

					.time-entry-name-wrapper {
						// Leaving this position:relative causes the overflow:hidden to be ignored
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						bottom: 0;
						overflow: hidden;
					}

					.time-entry-name {
						padding: 10px 12px;
						font-weight: 700;
						word-wrap: break-word;
						overflow: hidden;
						@include font-size(16);
						line-height: 1.3;
						user-select: none;
					}
					
					.time-entry-time-description {
						padding: 0px 12px;
						font-weight: 400;
						word-wrap: break-word;
						overflow: hidden;
						@include font-size(16);
						line-height: 1.3;
						user-select: none;
					}

					.draggable-tag {
						display: none;
					}

					.draggable-tag {
						display: flex;
						position: absolute;
						align-items: center;
						width: 100px;
						height: 40px;
						cursor: pointer;
						&.top {
							left: -45px;
							top: -20px;
						}
						&.bottom {
							right: -45px;
							bottom: -20px;
						}

						.icon {
							display: block;
							position: relative;
							width: 40px;
							height: 40px;
							background-repeat: no-repeat;
							background-position: center;
						}
						.time-stamp {
							display: flex;
							position: relative;
							justify-content: center;
							align-items: center;
							width: 60px;
							height: 26px;
							margin: 0 5px;
							border: 2px solid $color-mid-purple;
							background: transparent;
							@include font-size(16);
							text-align: center;
							@include border-radius(4px);
							opacity: 0;
							@include transition(opacity 0.3s);
							font-weight: 700;
							font-family: $font-display;
							user-select: none;
						}
					}

					&.mode-unlinked {
						.time-entry-block {
							background-color: $color-secondary;
						}

						.draggable-tag {
							&.top {
								.icon {
									background-image: url("../../assets/svg/time-entry-edit-tab-top-unlinked.svg");
								}
							}
							&.bottom {
								.icon {
									background-image: url("../../assets/svg/time-entry-edit-tab-bottom-unlinked.svg");
								}
							}
							.time-stamp {
								background: $color-secondary;
								color: $color-secondary-contrast-dark;
							}
						}
					}

					&.mode-linked {
						.time-entry-block {
							background-color: $color-primary;
						}

						.draggable-tag {
							&.top {
								.icon {
									background-image: url("../../assets/svg/time-entry-edit-tab-top-linked.svg");
								}
							}
							&.bottom {
								.icon {
									background-image: url("../../assets/svg/time-entry-edit-tab-bottom-linked.svg");
								}
							}
							.time-stamp {
								background: $color-primary;
								color: $color-primary-contrast-dark;
							}
						}
					}

					&.edit-mode {
						@include transition(none);
					}

					&.being-edited {
						z-index: 4;
						transition: none;
						&::after {
							content: "";
							display: block;
							width: 1080px;
							left: 50%;
							transform: translateX(-50%);
							background-color: rgba($color-text-contrast, 0.1);
							height: 100%;
							position: absolute;
							top: 0;
							z-index: -1;
						}
						.draggable-tag {
							.time-stamp {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}
