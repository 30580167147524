@import "../../../GlobalUtils/global-utils.scss";

.confirm-box-wrapper {

	.confirm-box-container {
		padding-top: 15px;
		padding-bottom: 10px;
		width: 400px;

		.confirmation-header {
			text-align: center;
			color: $color-primary;
			padding-bottom: 15px;
			font-size: 22pt;
		}

		.confirmation-message {
			text-align: center;
			padding: 10px;
			align-content: center;
		}

		.confirmation-buttons{
			display: flex;
			flex-direction: row;
			justify-content: center;
			padding-top: 20px;
		
			.option-button {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				position: relative;
				background-color: transparent;
				text-align: left;
				font-size: 15pt;
				color: $color-text-contrast;
				width: 40px;
				height: 40px;
				background-position: center;
				background-repeat: no-repeat;
				left: 0;
				padding: 0;
				margin-right: 20px;
				border: 1px solid transparent;
				@include interactive-states() {
					background-color: $color-primary;
					.label {
						color: $color-text;
					}
				}
				@include media-min-w(480px) {
					padding: 0 18px;
					background-color: $color-mid-purple;
					width: auto;
					border: 1px solid $color-primary;
				}

				&.cancel-button{
					margin-right: 0;
				}
			}
		}
	}
}