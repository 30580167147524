@import "../../../GlobalUtils/global-utils.scss";

// Sidebar horizontal and vertical break points
$sb-h-bp-xs: 480px;
$sb-h-bp-sm: 768px;
$sb-h-bp-lg: 1080px;
$sb-v-bp-sm: 768px;

.day-not-started {
	.comments-panel,
	.task-details-panel {
		height: 50%;
	}
}

.comments-panel {
	&.collapsed {
		max-height: 0px;
	}

	overflow: hidden;
	//   @include media-min-w($sb-v-bp-sm) {
	//     margin-bottom: 78px;
	//   }
	//   @media screen and (min-width: $sb-h-bp-sm) and (min-height:  $sb-v-bp-sm) {
	//     margin-bottom: 178px;
	//   }
	@media screen and (min-width: $sb-h-bp-sm) {
		max-height: 50%;
	}
	@media screen and (min-width: $sb-h-bp-sm) and (min-height: $sb-v-bp-sm) {
		// max-height: calc(100vh - 75px);
	}
}

.group-comments-container {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.group-comments-header-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	//   min-height: 58px;
	flex: 0;
	button {
		&.comment-toggle {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			flex-grow: 0;
			@extend %single-icon;
			background-size: 20px;
			background-image: url("../../assets/svg/chevron-white-outline.svg");
			transition: all 0.3s;
		}
	}
}

.group-comments-wrapper {
	//   overflow: hidden;
	//   transition: all 0.3s;

	.group-comments-header-wrapper {
		padding: 20px 20px 5px 20px;
	}

	.group-comments-content-wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		transition: all 0.6s linear 0s;
		width: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		flex: 1 1 auto;
		padding-top: 0px;
		padding-right: 20px;
		padding-bottom: 0px;
		padding-left: 20px;

		.time-entry-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			margin: 0 0 6px 0;
			flex-wrap: wrap;

			@include media-min-w($sb-h-bp-xs) {
				flex-wrap: nowrap;
				margin: 30px 0 15px 0;
			}
			@include media-min-w($sb-h-bp-sm) {
				flex-wrap: wrap;
				margin: 30px 0 6px 0;
			}
			@include media-min-w($sb-h-bp-lg) {
				flex-wrap: nowrap;
				margin: 30px 0 15px 0;
			}

			&:first-of-type {
				margin-top: 20px;
			}

			&:last-of-type {
				margin-bottom: 0px;
				@include media-min-w($sb-h-bp-sm) {
					margin-bottom: 30px;
				}
			}
			&.disabled {
				opacity: 0.4;
			}

			&:last-of-type {
				border-bottom: 0;
			}

			&.linked {
				.time-wrapper {
					&::before {
						background-color: $color-primary;
					}

					.time-in-hours {
						.time-value {
							color: $color-primary;
						}
					}
				}
			}

			&.unlinked {
				.time-wrapper {
					&::before {
						background-color: $color-secondary;
					}

					.time-in-hours {
						.time-value {
							color: $color-secondary;
						}
					}
				}
			}

			&.is-running {
				.time-wrapper {
					.time-in-hours {
						.is-running-icon {
							opacity: 1;
						}
					}
				}
			}

			.time-wrapper {
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				text-align: left;
				width: 75px;
				padding: 5px 0px 5px 20px;
				box-sizing: border-box;
				color: $color-primary-contrast;
				@include transition(width 0.3s);

				@include media-min-w(567px) {
					width: 125px;
				}

				&::before {
					background-color: $color-secondary;
					display: block;
					content: "";
					width: 5px;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					border-radius: 10px;
				}

				.start-end-time {
					@include font-size(15);
					font-weight: 500;
					margin-bottom: 4px;
					@include transition(font-size 0.3s);
					@include media-min-w(567px) {
						@include font-size(14);
					}
					span {
						display: block;
					}
				}

				.time-in-hours {
					.time-value {
						display: inline-block;
						position: relative;
						@include font-size(22);
						line-height: 1;
						font-weight: 700;
						.time-unit-indicator {
							font-weight: 400;
							margin-left: 2px;
							@include font-size(18);
						}
					}
					.is-running-icon {
						display: inline-block;
						position: relative;
						opacity: 0;
						width: 20px;
						height: 16px;
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url("../../../Components/assets/svg/task-icon-running-time-dark-purple.svg");
					}
				}
			}

			.comment-time-label {
				font-family: $font-display;
				@include font-size(15);
				line-height: 1.4;
				font-weight: 900;
				display: block;
				flex: 1 0 100%;
				padding-top: 2px;
				margin-bottom: 2px;

				@include media-min-w($sb-h-bp-xs) {
					flex: 0 0 75px;
				}
				@include media-min-w($sb-h-bp-sm) {
					flex: 1 0 100%;
				}
				@include media-min-w($sb-h-bp-lg) {
					flex: 0 0 75px;
				}
			}

			.comment-wrapper {
				flex: 1 1 auto;
				display: inline-block;
				white-space: normal;
				margin: 0;
				margin-top: 0px;
				margin-left: -10px;
				margin-right: -10px;
				@include font-size(19);
				@include media-min-w($sb-h-bp-xs) {
					//   margin-top: -8px;
					margin-right: 0;
					margin-left: 0;
				}
				@include media-min-w($sb-h-bp-sm) {
					padding-left: 0px;
					margin-top: 0px;
					margin-left: -10px;
					margin-right: -10px;
				}
				@include media-min-w($sb-h-bp-lg) {
					//   margin-top: -8px;
					margin-left: 0px;
					margin-right: 0px;
					.comment {
						// This is for all styling that needs to be the same between the shadow-comment and the textarea
						border: 0;
						background-color: $color-black-20;
						color: $color-text-contrast;
						@include font-size(18);
						line-height: 1.5;
						font-weight: 400;
						padding: 5px 15px;
						box-sizing: border-box;
						margin: 0;
					}
				}

				&.is-editing {
					.editable-content-wrapper {
						.editable-content-container {
							textarea {
								background-color: $color-text;
							}
						}
					}
				}
			}

			.editable-content-wrapper {
				.editable-content-container {
					//   .content-label {
					//     width: 100%;
					//     overflow: hidden;
					//     @include font-size(18);
					//     line-height: 1.5;
					//     @include transition(all 0.3s);
					//     @include media-min-w(567px) {
					//       @include font-size(18);
					//       line-height: 1.4;
					//     }
					//     .placeholder {
					//       color: rgba($color-text, 0.5);
					//     }
					//     > span {
					//       display: block;
					//       margin-bottom: 20px;
					//       &:last-of-type {
					//         margin-bottom: 0;
					//       }
					//     }
					//   }

					textarea {
						background-color: $color-black-20;
						color: $color-text-contrast;
						@include font-size(18);
						line-height: 1.5;
						font-weight: 400;
						padding: 4px 10px;
						&:focus,
						&:active,
						&active:focus {
							background-color: $color-text;
						}

						// @include media-min-w($sb-h-bp-xs) {
						//   flex: 0 0 75px;
						// }
						// @include media-min-w($sb-h-bp-sm) {
						//   flex: 1 0 100%;
						// }
						// @include media-min-w($sb-h-bp-lg) {
						//   flex: 0 0 75px;
						// }
					}
				}
			}
		}
	}

	.add-comment-wrapper {
		background-color: $color-mid-purple;
		padding: 10px 20px 20px 20px;
		position: relative;
		flex: 0;
		overflow: visible;
		z-index: 50;

		@include media-min-h($sb-h-bp-sm) {
			padding: 15px 20px 20px 20px;
		}

		@include media-min-w($sb-h-bp-sm) {
			position: fixed;
			bottom: 0;
			right: 0;
			padding-right: 40px;
			width: 350px;
		}

		@include media-min-w(1080px) {
			width: 500px;
		}

		.overflow-overlay {
			left: 0px;
			right: 18px;
			top: -40px;
			position: absolute;
			display: block;
			opacity: 0;
			height: 40px;
			transition: opacity 0.3s, height 0.3s, top 0.3s;
			background-image: linear-gradient(to top, $color-text 10%, transparent);
			z-index: 2;
			@include media-min-w($sb-h-bp-sm) {
				opacity: 1;
			}
			&.visible-true {
				opacity: 0;
				height: 0;
				top: 0px;

				@include media-min-w($sb-h-bp-sm) {
					opacity: 1;
					height: 40px;
					top: -40px;
				}
			}
			&.visible-false {
				opacity: 0;
				height: 0;
				top: 0px;
			}
		}

		.add-comment-form {
			position: relative;
		}

		fieldset {
			position: relative;

			border: 0;
			textarea {
				box-sizing: border-box;
				box-shadow: none;
				-webkit-appearance: none;
				border: 0px;
				overflow-x: hidden;
				overflow-y: hidden;
				margin: 0px;
				width: 100%;
				max-width: 100%;
				resize: vertical;
				color: $color-text-contrast;
				background: $color-text;
				resize: none;
				transition: all 0.3s;
				outline: 0;
				box-shadow: none;
				max-height: 42px;
				padding: 10px 55px 10px 10px;
				width: calc(100% - 45px);
				border-radius: 6px 0 0 6px;

				// @include interactive-states() {

				// }
				&:focus,
				&:active,
				&:active:focus {
					overflow-y: auto;
					background: darken($color-text, 2%);
				}
				@include media-min-h($sb-h-bp-sm) {
					max-height: 75px;
					padding: 20px;
					border-radius: 6px;
					width: 100%;
				}
			}
		}
		.add-comment-button {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			color: $color-text;
			background-color: $color-text;
			border: 0;
			position: absolute;
			right: 0;
			top: 0;
			height: 42px;
			width: 45px;
			margin: 0;
			padding: 0;
			border-radius: 0 6px 6px 0;

			@include interactive-states() {
				background-color: $color-primary;

				.icon {
					background-image: url("../../assets/svg/send-plane-dark-purple-fill.svg");
				}
			}
			.icon {
				display: block;
				position: relative;
				margin-top: -5px;
				background-image: url("../../assets/svg/send-plane-blue-fill.svg");
				width: 20px;
				height: 20px;
				background-size: 20px;
				@extend %single-icon;
			}
			.label {
				display: none;
			}

			@include media-min-h($sb-h-bp-sm) {
				position: relative;
				background-color: transparent;
				margin: 15px 0 0 auto;
				width: auto;
				padding: 0 15px;
				background-color: $color-primary;
				border-radius: 40px;
				height: 40px;

				@include interactive-states() {
					background-color: darken($color-primary, 6%);
				}

				.icon {
					display: block;
					margin-top: -5px;
					background-image: url("../../assets/svg/send-plane-dark-purple-fill.svg");
				}
				.label {
					display: block;
				}
			}
		}
	}
	.group-comments-header-wrapper {
		justify-content: center;
	}
	&.collapsed {
		.group-comments-header-wrapper {
			button {
				&.comment-toggle {
					transform: rotate(0deg);
				}
			}
		}
		.group-comments-content-wrapper {
			max-height: 0px;
		}

		.add-comment-wrapper {
			.overflow-overlay {
				visibility: hidden;
				// height: 0;
			}
		}
	}
	&.expanded {
		.group-comments-header-wrapper {
			button {
				&.comment-toggle {
					transform: rotate(180deg);
				}
			}
		}
		.group-comments-content-wrapper {
			max-height: 6000px;

			@media screen and (min-width: $sb-h-bp-sm) {
				max-height: 1000px;
			}
		}
	}
}
