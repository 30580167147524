@import "../../../GlobalUtils/global-utils.scss";

.playhead-info-wrapper {
	max-width: 100%;
	text-align: left;
	margin-bottom: 10px;
	align-content: center;

	.playhead-task-info {
		display: flex;

		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 20px 0;
		@include font-size(18);
		font-weight: 700;
		color: $color-text-contrast;
		margin-bottom: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		margin-bottom: 6px;
		padding-right: 10px;
		button {
			&.link,
			&.switch {
				width: 40px;
				height: 40px;
				border: 2px solid $color-secondary;
				background-color: $color-secondary;
				@include interactive-states() {
					background-color: $color-secondary;
				}
				&.disabled,
				&[disabled] {
					display: none;
				}
				@media screen and (min-width: 576px) and (min-height: 768px) {
					width: 50px;
					height: 50px;
				}
				@media screen and (min-width: 768px) and (min-height: 0px) {
					width: 45px;
					height: 45px;
				}
				@media screen and (min-width: 1080px) and (min-height: 768px) {
					width: 50px;
					height: 50px;
				}
			}

			&.link {
				background-image: url("../../assets/svg/link-dark-purple.svg");
				background-size: 110%;
				@include interactive-states() {
					background-image: url("../../assets/svg/link-dark-purple.svg");
				}
				&.disabled,
				&[disabled] {
					background-image: url("../../assets/svg/link-dark-purple.svg");
					@include interactive-states() {
						background-image: url("../../assets/svg/link-dark-purple.svg");
					}
				}
			}
		}
		.task-name,
		.task-name .group-name-label {
			position: relative;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		.task-name {
			width: 90%;
			.group-name-label {
				width: auto;
				line-height: 1;
			}
		}
		.group-indicator-no-icon + .task-name {
			top: 0;
		}
	}
}

.playhead-wrapper {
	background: $color-mid-purple;
	color: $color-text-contrast;
	display: block;
	padding: 20px;
	overflow: hidden;

	@include media-min-w(1080px) {
		padding: 25px;
	}

	.playhead-container {
		display: flex;
		flex: 1 0 auto;
		flex-direction: column;

		.timer-controls-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			margin: 0 auto;

			@include media-min-w(576px) {
				margin: 0 20px 0 0;
			}
			@include media-min-w(768px) {
				margin: 0 auto;
			}
			@include media-min-w(1080px) {
				margin: 0 20px 0 0;
			}
			.timer-display {
				&playhead-timer {
					@include font-size(32);
				}
				@include font-size(30);
				&.playhead-timer {
					display: block;
					position: relative;
					font-family: $font-display;
					line-height: 1;

					color: $color-text-contrast;
					font-family: $font-display;
					display: inline-block;
					text-align: center;
					@include media-min-w(576px) {
						text-align: left;
					}
					@include media-min-w(768px) {
						text-align: center;
					}
					@include media-min-w(1080px) {
						text-align: left;
					}

					.time-indicator,
					.time-unit-indicator {
						color: $color-white-50;
						display: inline-block;
						text-align: center;
						line-height: 1;
					}

					.time-indicator {
						font-weight: 400;
						@include mono-font-size(42);
					}

					.time-unit-indicator {
						font-weight: 400;
						margin-right: 6px;
						margin-left: 2px;
						@include font-size(36);
						vertical-align: top;
						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
			.timer-controls-container {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				button {
					&.edit,
					&.switch {
						width: 60px;
						height: 60px;
					}

					&.play {
						margin: 0;
						width: 60px;
						height: 60px;
						&.not-playing {
							background-position: 19px center;
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.playhead-wrapper {
		grid-row-start: 3;
		grid-row-end: 4;
		grid-column-start: 2;
		grid-column-end: 3;
	}
}
