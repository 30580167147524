.contextMenu {
	position: absolute;
	background: white;
	box-shadow: 0px 2px 10px #999999;
	z-index: 99;

	&--option {
		padding: 6px 50px 5px 10px;
		min-width: 160px;
		cursor: default;
		font-size: 12px;
		&:hover {
			background: linear-gradient(to top, #555, #333);
			color: white;
		}

		&:active {
			color: #e9e9e9;
			background: linear-gradient(to top, #555, #444);
		}

		&.disabled {
			color: #999999;
			pointer-events: none;
		}
		&__disabled {
			color: #999999;
			pointer-events: none;
		}
	}

	&--separator {
		width: 100%;
		height: 1px;
		background: #cccccc;
		margin: 0 0 0 0;
	}
}
