@import "../../../GlobalUtils/global-utils.scss";

.timer-display {
	transition: opacity 0.3s;
	&.is-playing {
		opacity: 1;
	}
	&.not-playing {
		opacity: 0.5;
	}
}
