@import "../../../GlobalUtils/global-utils.scss";

$group-label-h-offset: 8px;
$group-label-v-offset: 2.4rem;

.group-header-wrapper {
	display: block;
	position: relative;
	width: 100%;

	&.linked {
		.group-details-wrapper {
			//   width: calc(100% - 80px);
		}
		.group-total-time {
			color: $color-primary;
		}
	}

	&.unlinked {
		.project-name,
		.customer-name {
			display: none;
		}
		.group-total-time {
			color: $color-secondary;
		}
	}

	.group-header-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		width: 100%;
		align-items: flex-start;
		justify-content: space-between;
		margin-bottom: 10px;

		.group-controls-wrapper {
			display: none;
			flex: 0 0 50px;
			width: 50px;
			height: 50px;
			margin-right: 15px;
			flex-direction: column;
			justify-content: start;
			align-items: center;

			@include media-min-w(480px) {
				display: flex;
			}
			@include media-min-w(768px) {
				display: none;
			}
			@include media-min-w(900px) {
				display: flex;
			}
			@include media-min-w(1470px) {
				position: absolute;
				left: -65px;
			}

			button {
				&.play,
				&.switch {
					display: none;
					width: 50px;
					height: 50px;
					border: 2px solid $color-primary;
					&.disabled,
					&[disabled] {
						border: 2px solid $color-text-contrast;
						@include interactive-states() {
							cursor: auto;
							border: 2px solid $color-text-contrast;
							background-color: transparent;
						}
					}
					@media screen and (min-width: 768px) {
						display: block;
					}
				}

				&.play {
					background-size: 20px;
					background-color: $color-primary;
					@include interactive-states() {
						background-color: darken($color-primary, 10%);
						border: 2px solid darken($color-primary, 10%);
					}
					&.not-playing {
						background-position: 15px center;
						background-image: url("../../assets/svg/play-white-outline.svg");
					}
					&.is-playing {
						background-position: 13px center;
						background-image: url("../../assets/svg/pause-white.svg");
					}
				}

				&.switch {
					background-size: 20px;
					background-image: url("../../assets/svg/switch-blue.svg");
					background-color: transparent;
					@include interactive-states() {
						background-color: $color-primary;
						border: 2px solid $color-primary;
						background-image: url("../../assets/svg/switch-white.svg");
					}
				}
			}
		}

		.group-details-wrapper {
			display: block;
			flex: 1 1 auto;
			width: calc(100% - 185px); // Allow space for max number of buttons + 15px margin per button
			padding: 0;

			.group-name {
				display: block;
				margin-bottom: 2px;
				position: relative;
				margin-left: -#{$group-label-h-offset};
				@include font-size(21);
				font-weight: 700;
				display: block;
				width: auto;
				height: $group-label-v-offset;
				line-height: $group-label-v-offset;

				.group-name-wrapper {
					display: flex;
				}

				h2.name-label {
					@include font-size(22);
					font-family: $font-display;
					height: 100%;
					margin: 0;
					line-height: $group-label-v-offset;
					display: block;
					padding: 0;
					padding-left: $group-label-h-offset;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				.editable-label {
					height: 100%;
					label,
					div,
					h2,
					p {
						display: block;
						width: 100%;
						height: 100%;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
					.editable-label-input-wrapper {
						width: 100%;
						height: 100%;
						border: 0;
						input.editable-label-input-box {
							@include font-size(22);
							vertical-align: top;
							font-family: $font-display;
							padding: 0 $group-label-h-offset;
							line-height: 1;
							width: 100%;
							height: 100%;
							border: 0;
							border-radius: 4px;
							outline: none;
							font-weight: 900;
							background-color: transparent;
							transition: background-color 0.3s;
							&:focus {
								background-color: rgba($color-secondary, 0.15);
							}
						}
					}
				}
			}

			.group-details-container {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-wrap: wrap;
				flex-direction: row;

				.project-name,
				.customer-name {
					margin-bottom: 8px;
					overflow: hidden;

					.label {
						line-height: 1;
						padding-top: 4px;
						width: calc(100% - 30px);
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
					}
				}

				.project-name {
					.icon {
						background-image: url("../../assets/svg/task-icon-project-name-blue-solid.svg");
					}
				}
				.customer-name {
					padding-right: 15px;
					.icon {
						background-image: url("../../assets/svg/task-icon-customer-name-blue-solid.svg");
					}
				}
			}
			.group-total-time {
				font-family: $font-display;
				flex: 0 0 100%;
				line-height: 1.2;

				.total-time {
					@include font-size(26);
					font-weight: 900;
					margin-right: 2px;
				}
				.time-unit-indicator {
					@include font-size(21);
				}
			}

			.task-sync-warning {
				position: absolute;
				width: 20pt;
				height: 20pt;
				top: 12px;
				left: -25px;
				background-image: url("../../assets/svg/link-blue.svg");
			}
		}

		.group-actions-wrapper {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: flex-end;
			align-items: flex-start;
		}

		button {
			&.group-link,
			&.group-context-menu {
				height: 45px;
				font-size: 20pt;
				padding: 0;
				border-radius: 50%;
				margin-left: 15px;
			}
			&.group-link {
				width: 45px;
				flex: 0 0 45px;
				background-image: url("../../assets/svg/link-white.svg");
				background-color: $color-secondary;
			}

			&.group-context-menu {
				width: 40px;
				flex: 0 0 40px;
				background-image: url("../../assets/svg/context-menu-dark-purple.svg");
				background-color: transparent;
				opacity: 0.4;
				background-size: 18px;
				@include interactive-states() {
					opacity: 0.75;
				}
			}
		}
	}
}
