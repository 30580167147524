button,
a.button,
input.button {
	border: 0;
	background-color: transparent;
	padding: 0px 18px;
	font-family: $font-display;
	@include transition(all 0.3s);
	font-weight: 500;

	@include interactive-states() {
		box-shadow: 0;
		cursor: pointer;
		outline: 0;
	}

	&.disabled,
	&[disabled],
	&.disabled {
		cursor: auto;
		opacity: 0.25;
	}

	&.icon-only {
		background-repeat: no-repeat;
		background-position: center;
		@include interactive-states() {
			background-repeat: no-repeat;
		}
	}

	&.primary {
		background-color: $color-primary;
		border: 1px solid $color-primary;
		color: $color-primary-contrast;
		@include interactive-states() {
			background-color: darken($color-primary, 8%);
			border: 1px solid darken($color-primary, 8%);
		}

		&.inverse {
			background-color: $color-primary-contrast;
			border: 1px solid $color-primary-contrast;
			color: $color-primary;
			@include interactive-states() {
				background-color: $color-primary;
				border: 1px solid $color-primary;
				color: $color-primary-contrast;
			}
		}

		&.outline {
			border: 1px solid $color-primary;
			color: $color-primary;
			background-color: transparent;
			@include interactive-states() {
				background-color: $color-primary;
				border: 1px solid $color-primary;
				color: $color-primary-contrast;
			}
		}

		&.color-text {
			color: $color-text;
		}
	}

	&.secondary {
		background-color: $color-secondary;
		border: 1px solid $color-secondary;
		color: $color-secondary-contrast;
		@include interactive-states() {
			background-color: darken($color-secondary, 8%);
			border: 1px solid darken($color-secondary, 8%);
		}

		&.inverse {
			background-color: $color-secondary-contrast;
			border: 1px solid $color-secondary-contrast;
			color: $color-secondary;
			@include interactive-states() {
				background-color: $color-secondary;
				border: 1px solid $color-secondary;
				color: $color-secondary-contrast;
			}
		}

		&.outline {
			border: 1px solid $color-secondary;
			color: $color-text;
			background-color: transparent;
			@include interactive-states() {
				background-color: $color-secondary;
				border: 1px solid $color-secondary;
				color: $color-secondary-contrast;
			}
		}
	}

	&.tertiary {
		background-color: $color-tertiary;
		border: 1px solid $color-tertiary;
		color: $color-tertiary-contrast;
		@include interactive-states() {
			background-color: darken($color-tertiary, 8%);
			border: 1px solid darken($color-tertiary, 8%);
		}

		&.inverse {
			background-color: $color-tertiary-contrast;
			border: 1px solid $color-tertiary-contrast;
			color: $color-tertiary;
			@include interactive-states() {
				background-color: $color-tertiary;
				border: 1px solid $color-tertiary;
				color: $color-tertiary-contrast;
			}
		}

		&.outline {
			border: 1px solid $color-tertiary;
			color: $color-tertiary;
			background-color: transparent;
			@include interactive-states() {
				background-color: $color-tertiary;
				border: 1px solid $color-tertiary;
				color: $color-tertiary-contrast;
			}
		}
	}

	&.pill {
		.icon {
			display: inline-block;
			position: relative;
			background-repeat: no-repeat;
			background-position: center;
		}
		&.sz-1 {
			@include border-radius(15px);
			@include font-size(16);
			height: 30px;
			.icon {
				height: 16px;
				width: 16px;
				margin-right: 6px;
				background-size: 11px;
				top: 2px;
			}
		}
		&.sz-2 {
			@include border-radius(20px);
			@include font-size(18);
			height: 40px;
			.icon {
				height: 22px;
				width: 22px;
				margin-right: 8px;
				background-size: 15px;
				top: 3px;
			}
		}
		&.sz-3 {
			@include border-radius(30px);
			height: 60px;
			@include font-size(21);
			.icon {
				height: 24px;
				width: 24px;
				margin-right: 10px;
				background-size: 18px;
				top: 3px;
			}
		}
	}

	&.circle {
		@include border-radius(30px);
		&.sz-1 {
			@include border-radius(15px);
		}
		&.sz-2 {
			@include border-radius(20px);
		}
		&.sz-3 {
			@include border-radius(30px);
		}
	}

	&.square {
		&.sz-1 {
			@include border-radius(4px);
		}
		&.sz-2 {
			@include border-radius(4px);
		}
		&.sz-3 {
			@include border-radius(6px);
		}
	}

	&.circle,
	&.square {
		padding: 0;
		overflow: hidden;
		&.sz-1 {
			width: 30px;
			height: 30px;
		}
		&.sz-2 {
			width: 40px;
			height: 40px;
		}
		&.sz-3 {
			width: 60px;
			height: 60px;
		}
	}

	&.danger {
		background-color: red;
		color: white;
	}

	&.warning {
		background-color: orange;
	}

	&.success {
		background-color: green;
		color: white;
	}

	.sr-only {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border: 0;
	}
}

.play-button,
.switch-button {
	background-position: center;
	background-size: 20px;
	background-repeat: no-repeat;
}

.play-button {
	position: relative;
	//margin-right: 16px;

	&.not-playing {
		background-position: 10px center;
	}
}

.switch-button {
	position: relative;
	//margin-right: 15px;
}

button {
	&.edit,
	&.play,
	&.switch {
		padding: 0;
		position: relative;
		@include transition(all 0.3s);
	}
}

@include keyframes(shadow-pulse-secondary) {
	0% {
		box-shadow: 0 0 0 0px rgba($color-secondary, 0.6);
	}
	100% {
		box-shadow: 0 0 0 8px rgba($color-secondary, 0);
	}
}

@include keyframes(shadow-pulse-primary) {
	0% {
		box-shadow: 0 0 0 0px rgba($color-primary, 0.6);
	}
	100% {
		box-shadow: 0 0 0 8px rgba($color-primary, 0);
	}
}
