@import "../../../GlobalUtils/global-utils.scss";

#comments-view-header {
	color: $color-text-contrast;
	border-bottom: 1px solid #594051;
}

#comments-view-controls-header {
	width: 100%;
	max-width: 800px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
}

@media (min-width: 1280px) {
	#comments-view-controls-header {
		flex-wrap: unset;
	}
}

.time-totals {
	display: flex;
	gap: 5px;
	align-items: baseline;
	font-size: 14px;
	& .timer-display {
		font-family: "Dosis", sans-serif;
	}
}

.start-button-large {
	background-color: #faa05f;
	background-size: 31px;
	background-repeat: no-repeat;
	background-position: 25px;
	border-radius: 41px;
	padding: 20px 31px 20px 71px;
	background-image: url("../../assets/svg/play-white-outline.svg");
	font-size: 20px;
}

.start-new-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;

	p {
		max-width: 245px;
		text-align: center;
		margin-bottom: 30px;
		opacity: 0.5;
	}
}

.toggle-time-wrapper,
.time-edit-buttons-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;
}

.toggle-wrapper {
	button {
		&#orderby-time {
			background-image: url("../../assets/svg/task-icon-history-blue-outline.svg");
			&.active {
				background-image: url("../../assets/svg/task-icon-history-white-outline.svg");
			}
		}
		&#orderby-group {
			background-image: url("../../assets/svg/orderby-a-z-desc-blue-outline.svg");
			&.active {
				background-image: url("../../assets/svg/orderby-a-z-desc-white-outline.svg");
			}
		}
	}
}

button.icon-text {
	background-size: 13px;
	background-repeat: no-repeat;
	background-position: 15px;
	border: 2px solid #32d2d7;
	border-radius: 20px;
	padding: 10px 20px 10px 38px;
	color: white;

	&.add-time-entry {
		background-image: url("../../assets/svg/plus-blue.svg");
	}

	&.open-timeline-editor {
		background-image: url("../../assets/svg/pencil-blue-outline.svg");
	}

	&.open-tasklist {
		background-image: url("../../assets/svg/search-blue-outline.svg");
	}
}

@media (max-width: 450px), (min-width: 768px) and (max-width: 800px) {
	#comments-view-controls-header button.icon-text {
		height: 40px;
		padding: 10px 18px;
		background-position: 12px;

		span {
			display: none;
		}
	}
}

.header-left-content {
	display: flex;
}
