@import "../../../GlobalUtils/global-utils.scss";

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 9999;

	&.display-block {
		display: block;
		.modal-main {
			@include transform(translate(-50%, -50%), scale(1));
		}
	}

	&.display-none {
		display: none;
		.modal-main {
			@include transform(translate(-50%, -50%), scale(0.8));
		}
	}

	.modal-main-wrapper {
		position: absolute;
		background: transparent;
		top: calc(50% + 20px);
		left: 50%;
		transform: translate(-50%, -50%);
		transition: transform 0.3s, top 0.3s;
		z-index: 999;
		@include border-radius(15px);

		.modal-main-container {
			@include flex-display();
			@include flex-direction(column);
			position: relative;
			flex: 1 0 auto;
			width: 100%;
			height: 100%;
		}
	}

	.click-off-close {
		width: 100%;
		height: 100%;
	}

	.close-button {
		position: absolute;
		background-image: url("../../assets/svg/close-white-outline.svg");
		z-index: 3;
		right: 0;
		top: -40px;
		right: 15px;
		background-size: 16px;
		@include transform(rotate(0deg));
		@include interactive-states() {
			@include transform(rotate(180deg));
		}
	}

	.save-button {
		position: absolute;
		right: 2%;
		bottom: 2%;
		width: 80px;
		height: 50px;
		background-color: green;
		font-size: 18pt;
	}
}
