.overflow-x-visible {
	height: 100%;
	width: 100%;
	overflow-x: visible;
	overflow-y: hidden;
	position: relative;
	display: block;
	left: 0;
	background-color: white;

	.overflow-y-scroll {
		height: 100%;
		position: relative;
		display: block;
		width: 100%;
		//direction: rtl;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 15px;
			background: rgba($color-primary, 0.3);
		}

		&::-webkit-scrollbar-thumb {
			background: rgba($color-primary, 0.6);
		}
	}
}
