@import "../../GlobalUtils/global-utils.scss";

.task-list-header {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 5px;
	margin-bottom: 15px;
}

.task-list-container {
	.spinner-container,
	.no-results {
		color: $color-text-contrast;
		display: grid;
		width: 100%;
		height: 100%;
		justify-items: center;
		align-content: center;
	}
}

.list-title-wrapper {
	color: $color-text-contrast;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	.icon {
		display: inline-block;
		position: relative;
		top: 2px;
		width: 22px;
		height: 22px;
		flex: 0 0 22px;
		margin-right: 10px;
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: center;
		opacity: 0.4;
	}
	h2 {
		@include font-size(18);

		flex: 1 1 auto;
		text-transform: uppercase;
		font-weight: 400;
		.search-description-label {
			font-weight: 500;
		}
	}
	&.mode-search {
		.icon {
			background-image: url("../../Components/assets/svg/search-white-outline.svg");
		}
	}
	&.mode-orderby-recent {
		.icon {
			background-image: url("../../Components/assets/svg/task-icon-history-white-outline.svg");
		}
	}
	&.mode-filter-favourites {
		.icon {
			background-image: url("../../Components/assets/svg/task-icon-favourites-white-solid.svg");
		}
	}

	&.mode-orderby-most-used {
		.icon {
			background-image: url("../../Components/assets/svg/task-icon-most-used-white-outline.svg");
		}
	}

	&.mode-orderby-due-date {
		.icon {
			background-image: url("../../Components/assets/svg/calendar-white-solid.svg");
		}
	}

	&.mode-orderby-new {
		.icon {
			background-image: url("../../Components/assets/svg/task-icon-new-white-solid.svg");
		}
	}
}

.size-toggle-wrapper {
	display: block;
	margin-left: auto;
	flex: 1 1 auto;
	text-align: right;

	.size-button {
		display: inline-block;
		padding: 0;
		height: 30px;
		width: 30px;
		border-radius: 50%;
		margin-left: 15px;
		border: 1px solid $color-text-contrast;
		color: $color-text-contrast;
		font-weight: 700;

		@include interactive-states() {
			background-color: $color-text-contrast;
			color: $color-text;
		}
		&.active {
			color: $color-text;
			background-color: $color-text-contrast;
		}

		// &.small {
		// 	&.active {
		// 		color: $color-text;
		// 		background-color: $color-text-contrast;
		// 	}
		// 	//   @include media-min-w(768px) {
		// 	//     display: none;
		// 	//   }
		// }
		// &.medium {
		// 	&.active {
		// 		color: $color-text;
		// 		background-color: $color-text-contrast;
		// 	}
		// }
		// &.large {
		// 	&.active {
		// 		color: $color-text;
		// 		background-color: $color-text-contrast;
		// 	}
		// }
	}
}

.select-number-to-show-wrapper {
	display: block;
	margin-left: auto;
	flex: 1 1 auto;
	text-align: left;
	.number-to-show-button {
		display: inline-block;
		padding: 0;
		height: 30px;
		padding: 4px;
		padding-left: 8px;
		padding-right: 8px;
		//width: 30px;
		border-radius: 20px;
		margin-left: 15px;
		border: 1px solid $color-text-contrast;
		color: $color-text-contrast;
		font-weight: 700;

		@include interactive-states() {
			background-color: $color-text-contrast;
			color: $color-text;
		}
		&.active {
			color: $color-text;
			background-color: $color-text-contrast;
		}
	}
}

.grouped-entries-label {
	color: white;
}
