@import "../../../GlobalUtils/global-utils.scss";

.timeline-content-wrapper {
	.timeline-content-container {
		.time-indicators-wrapper {
			height: 100%;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 1;
			.hour,
			.quarter-hour,
			.half-hour {
				display: block;
				position: absolute;
				width: 100%;
				height: 1px;
			}
			.hour {
				border-top: 2px solid rgba($color-text-contrast, 0.1);
			}
			.half-hour {
				border-top: 1px dashed rgba($color-text-contrast, 0.1);
			}
			.quarter-hour {
				border-top: 1px dotted rgba($color-text-contrast, 0.1);
			}

			.hours-container,
			.hours-label-container,
			.half-hours-container,
			.quarter-hours-container {
				height: 100%;
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			.hours-label-container {
				.hour-label {
					display: block;
					position: absolute;
					@include font-size(12);
					margin: 6px;
					color: rgba($color-text-contrast, 0.7);

					@include media-min-w(768px) {
						@include font-size(14);
					}
				}
			}
		}
	}
}
