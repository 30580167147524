@import "../../GlobalUtils/global-utils.scss";

.export-button {
	height: 60px;
	width: 120px;
	background-color: green;
	color: black;
	border-radius: 30px;
	padding: 20px;

	&:disabled {
		background-color: grey;
	}

	.spinner {
		margin-left: 20px;
		width: 40px;
		height: 40px;
		border-top-color: black;
		border-top-style: solid;
		border-radius: 50%;
		-webkit-animation-name: spin;
		-webkit-animation-duration: 2000ms;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-timing-function: linear;
		-moz-animation-name: spin;
		-moz-animation-duration: 2000ms;
		-moz-animation-iteration-count: infinite;
		-moz-animation-timing-function: linear;
		-ms-animation-name: spin;
		-ms-animation-duration: 2000ms;
		-ms-animation-iteration-count: infinite;
		-ms-animation-timing-function: linear;

		animation-name: spin;
		animation-duration: 2000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
