/*
--
Theme
--
*/

@font-face {
	font-family: Dosis;
	src: url("./Fonts/Dosis/static/Dosis-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: Dosis;
	src: url("./Fonts/Dosis/static/Dosis-Bold.ttf");
	font-weight: 700;
}

@supports (font-variation-settings: "wdth" 115) {
	@font-face {
		font-family: Dosis;
		src: url("./Fonts/Dosis/Dosis-VariableFont_wght.ttf");
		font-weight: 300 700;
	}
}

@font-face {
	font-family: Rubik;
	src: url("./Fonts/Rubik/Rubik-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: Rubik;
	src: url("./Fonts/Rubik/Rubik-Regular.ttf");
	font-weight: 400;
}
@font-face {
	font-family: Rubik;
	src: url("./Fonts/Rubik/Rubik-Medium.ttf");
	font-weight: 500;
}
@font-face {
	font-family: Rubik;
	src: url("./Fonts/Rubik/Rubik-Bold.ttf");
	font-weight: 700;
}

body {
	font-family: $font-body;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.6;
	@include font-size(18);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-display;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
