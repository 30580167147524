@import "../../../GlobalUtils/global-utils.scss";

.when-did-you-finish {
	.header-section {
	}

	.modal-content-wrapper {
		.modal-content {
			color: white;

			.description {
			}

			.set-day-time {
				display: inline-block;
				white-space: nowrap;
				width: 100%;

				color: white;

				.start-time {
				}

				.hour-input-wrapper {
					display: inline-block;
					width: 30px;
					margin-left: 8px;
					margin-right: 5px;
					.hour-input {
						width: 30px;
					}
				}

				.minute-input-wrapper {
					display: inline-block;
					width: 30px;
					margin-left: 5px;
					margin-right: 5px;
					.minute-input {
						width: 30px;
					}
				}

				.am-pm-toggle {
					display: inline-block;
					margin-left: 5px;
					// position: absolute;
					.am-button {
						background-color: lightgray;
					}
					.am-button:disabled {
						color: white;
						background-color: darkgray;
					}

					.pm-button {
						background-color: lightgray;
					}
					.pm-button:disabled {
						color: white;
						background-color: darkgray;
					}
				}

				.date-of-end-time {
					color: white;
					display: inline-block;
					margin-left: 10px;
				}

				.error-message-label {
					color: red;
				}
			}

			.swap-to-next-day {
				color: white;
			}

			.still-working {
				color: white;
			}
		}
	}
}

.header-section {
	color: white;
}

.save-time {
	color: white;
	background-color: green;
}
