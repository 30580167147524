@import "../../../GlobalUtils/global-utils.scss";

.modal.search-modal {
	.modal-main-wrapper {
		@media screen and (min-width: 900px) {
			top: 50%;
		}
	}
	.close-button {
		@media screen and (min-width: 900px) {
			top: 20px;
		}
		@media screen and (min-width: 900px) and (min-height: 568px) {
			top: 25px;
		}
		@include media-min-w(950px) {
			right: 25px;
		}
	}
}

#search-wrapper {
	background: $color-mid-purple;
	height: calc(100vh - 70px);
	width: calc(100vw - 30px);
	max-width: 1080px;
	max-height: 1080px;
	overflow: hidden;
	border-radius: 6px;
	@media screen and (min-width: 900px) {
		height: calc(100vh - 30px);
	}
}
