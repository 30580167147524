@import "../../../GlobalUtils/global-utils.scss";

.dashboard-halo-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	@include transition(all 0.3s);
	max-width: 200px;

	@media screen and (min-width: 420px) and (min-height: 768px) {
		max-width: 300px;
	}

	@media screen and (min-width: 576px) and (min-height: 850px) {
		max-width: 350px;
	}

	@media screen and (min-width: 768px) and (min-height: 0px) {
		max-width: 300px;
	}

	@media screen and (min-width: 1080px) and (min-height: 768px) {
		max-width: 350px;
	}

	@media screen and (min-width: 1080px) and (min-height: 950px) {
		max-width: 450px;
	}

	.dashboard-halo-container {
		width: 100%;
		padding-top: 100%;
		position: relative;
		overflow: hidden;
		margin-top: 10px;
		margin-bottom: 10px;
		@include transition(all 0.3s);

		@media screen and (min-height: 768px) {
			margin-top: 15px;
			margin-bottom: 15px;
		}

		@media screen and (min-width: 768px) and (min-height: 768px) {
			margin-top: 30px;
			margin-bottom: 30px;
		}

		.halo-graphic {
			position: absolute;
			top: 0;
			width: 100%;
		}

		.halo-details {
			color: $color-text-contrast;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			width: 100%;
			left: 50%;
			top: 50%;
			@include transform(translate(-50%, -50%));

			.total-time {
				display: flex;
				margin-bottom: 5px;
				width: fit-content;
				@include transition(all 0.3s);

				@include font-size(18);

				@media screen and (min-width: 420px) and (min-height: 768px) {
					@include font-size(21);
				}

				@media screen and (min-width: 576px) and (min-height: 850px) {
					@include font-size(24);
				}

				@media screen and (min-width: 768px) and (min-height: 0px) {
					@include font-size(21);
				}

				@media screen and (min-width: 1080px) and (min-height: 768px) {
					@include font-size(24);
				}

				@media screen and (min-width: 1080px) and (min-height: 950px) {
					@include font-size(28);
					margin-bottom: 8px;
				}

				.halo-total-label,
				.halo-total-time {
					font-family: $font-display;
					display: inline-block;
				}

				.halo-total-label {
					font-weight: 900;
					letter-spacing: 0.5px;
					margin-right: 5px;
				}

				.halo-total-time {
					font-weight: 400;
				}
			}

			.halo-timer-running-group {
				position: relative;
				margin-bottom: 8px;
				font-family: $font-display;
				transition: width 0.3s;

				.time-indicator,
				.time-unit-indicator {
					display: inline-block;
					text-align: center;
					line-height: 1;
				}

				.time-indicator {
					font-weight: 500;
					@include mono-font-size(28);
					transition: font-size 0.3s;

					@media screen and (min-width: 420px) and (min-height: 768px) {
						@include mono-font-size(42);
					}

					@media screen and (min-width: 576px) and (min-height: 850px) {
						@include mono-font-size(50);
					}

					@media screen and (min-width: 768px) and (min-height: 0px) {
						@include mono-font-size(42);
					}

					@media screen and (min-width: 1080px) and (min-height: 768px) {
						@include mono-font-size(50);
					}

					@media screen and (min-width: 1080px) and (min-height: 950px) {
						@include mono-font-size(65);
						margin-bottom: 25px;
					}
				}

				.time-unit-indicator {
					font-weight: 400;
					margin-left: 4px;
					margin-right: 10px;
					@include font-size(18);
					@include transition(all 0.3s);
					&:last-of-type {
						margin-right: 0;
					}

					@media screen and (min-width: 420px) and (min-height: 768px) {
						@include font-size(22);
					}

					@media screen and (min-width: 576px) and (min-height: 850px) {
						@include font-size(24);
					}

					@media screen and (min-width: 768px) and (min-height: 0px) {
						@include font-size(22);
					}

					@media screen and (min-width: 1080px) and (min-height: 768px) {
						@include font-size(24);
					}

					@media screen and (min-width: 1080px) and (min-height: 950px) {
						@include font-size(26);
						margin-right: 15px;
					}
				}

				&.is-unlinked {
					color: $color-secondary;
				}
			}

			.halo-task-name {
				position: relative;
				@include font-size(24);
				margin-bottom: 10px;
			}
		}
	}
}

//
// HALO CONTROLS
//

main.day-not-started {
	.timer-controls-wrapper {
		.timer-controls-container {
			button {
				&.play {
					background-color: $color-secondary;
					@include interactive-states() {
						background-color: darken($color-secondary, 10%);
					}
				}
			}
		}
	}
}

.timer-controls-wrapper {
	display: block;
	width: 100%;

	.timer-controls-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		button {
			&.edit,
			&.switch {
				width: 40px;
				height: 40px;
				border: 2px solid $color-primary;
				background-color: transparent;
				@include interactive-states() {
					background-color: $color-primary;
				}
				&.disabled,
				&[disabled] {
					border: 2px solid $color-text-contrast;
					@include interactive-states() {
						cursor: auto;
						border: 2px solid $color-text-contrast;
						background-color: transparent;
					}
				}
				@media screen and (min-width: 576px) and (min-height: 768px) {
					width: 50px;
					height: 50px;
				}
				@media screen and (min-width: 768px) and (min-height: 0px) {
					width: 45px;
					height: 45px;
				}
				@media screen and (min-width: 1080px) and (min-height: 768px) {
					width: 50px;
					height: 50px;
				}
			}

			&.edit {
				background-image: url("../../assets/svg/pencil-blue-outline.svg");
				background-size: 46%;
				@include interactive-states() {
					background-image: url("../../assets/svg/pencil-dark-purple-outline.svg");
				}
				&.disabled,
				&[disabled] {
					background-image: url("../../assets/svg/pencil-white-outline.svg");
					@include interactive-states() {
						background-image: url("../../assets/svg/pencil-white-outline.svg");
					}
				}
			}

			&.play {
				margin: 0 30px;
				background-size: 42%;
				width: 55px;
				height: 55px;
				border-radius: 80px;
				@media screen and (min-width: 576px) and (min-height: 768px) {
					margin: 0 30px;
					width: 80px;
					height: 80px;
				}

				@media screen and (min-width: 768px) and (min-height: 0px) {
					margin: 0 20px;
					width: 60px;
					height: 60px;
				}

				@media screen and (min-width: 1080px) and (min-height: 768px) {
					margin: 0 30px;
					width: 80px;
					height: 80px;
				}

				background-color: $color-primary;
				@include interactive-states() {
					background-color: darken($color-primary, 10%);
				}
				&.not-playing {
					background-position: 18px center;
					background-image: url("../../assets/svg/play-dark-purple-outline.svg");
					@media screen and (min-width: 576px) and (min-height: 768px) {
						background-position: 25px center;
					}
					@media screen and (min-width: 768px) and (min-height: 0px) {
						background-position: 18px center;
					}
					@media screen and (min-width: 1080px) and (min-height: 768px) {
						background-position: 25px center;
					}
				}
				&.is-playing {
					background-image: url("../../assets/svg/pause-dark-purple.svg");
				}
			}

			&.switch {
				background-size: 46%;
				background-image: url("../../assets/svg/switch-blue.svg");
				@include interactive-states() {
					background-image: url("../../assets/svg/switch-dark-purple.svg");
				}
				&.disabled,
				&[disabled] {
					background-image: url("../../assets/svg/switch-white.svg");
					@include interactive-states() {
						background-image: url("../../assets/svg/switch-white.svg");
					}
				}
			}
		}
	}
}

.halo-time-labels-wrapper {
	width: 100%;
	position: relative;
	display: block;
	text-align: center;
	.halo-time-label-wrapper {
		font-family: $font-display;
		display: inline-block;
		position: relative;
		color: $color-text-contrast;
		z-index: 9;
		padding: 3px 10px;
		top: 0;
		background-color: rgba($color-text-contrast, 0.15);

		&.start-time {
			margin-left: 3%;
			@include border-radius(0 2px 2px 0);
		}

		&.custom-end-time {
			margin-right: 3%;
		}

		&.current-time {
			margin-right: 3%;
			@include border-radius(2px 0 0 2px);
		}

		@media screen and (min-height: 768px) {
			padding: 6px 12px;
		}
	}

	.halo-time-label-editing {
		position: relative;
		color: $color-text-contrast;
		padding-top: 15px;
		z-index: 99;

		&.start-time {
			left: 43%;
		}

		&.custom-end-time {
			left: 25%;
		}

		&.current-time {
			left: 25%;
		}
	}

	.line {
		padding-top: 90px;

		.halo-time-label {
			// display: block;
			position: absolute;
			color: $color-text;
			z-index: 9;
			padding: 6px 12px;
			top: 0;
			background-color: rgba($color-text-contrast, 0.15);
			left: 0;
			// color:black;

			&.start-time {
				top: 50px;
				@include border-radius(0 2px 2px 0);
			}

			&.custom-end-time {
				top: 100%;
			}

			&.current-time {
				top: 100%;
				@include border-radius(2px 0 0 2px);
			}
		}

		.halo-time-label-editing {
			position: absolute;
			color: $color-text-contrast;
			padding-top: 15px;
			z-index: 99;
			left: 0;

			&.start-time {
				top: 50px;
			}

			&.custom-end-time {
				top: 100%;
			}

			&.current-time {
				top: 100%;
			}
		}
	}
}

.halo-container {
	display: block;
	position: relative;
	@media screen and (min-width: 576px) and (min-height: 850px) {
		padding-top: 0;
	}
}

.group-info {
	.halo-container {
		#halo {
			position: absolute;
			transform: translateX(-50%);
			text-align: center;
			margin-left: 50px;

			#halo-line {
				stroke: rgba(255, 255, 255, 0.6);
			}
		}
	}
}
