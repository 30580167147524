@import "../../../../GlobalUtils/global-utils.scss";

.menu-wrapper {
	.menu-option {
		color: $color-text-contrast;
		width: auto;
		@include font-size(22);
		padding: 12px;
		background-color: transparent;
		border-radius: 4px;
		@include transition(all 0.3s);
		display: inline;
		@include interactive-states() {
			background-color: rgba(black, 0.3);
			cursor: pointer;
		}
		@media screen and (min-width: 768px) and (min-height: 768px) {
			@include font-size(28);
			padding: 15px 25px;
		}
	}
}

.playhead-wrapper .menu .menu-option {
	padding-left: 10%;
}
