@import "../../../GlobalUtils/global-utils.scss";

.comments-summary {
	position: relative;

	.comment-time-label {
		font-family: $font-display;
		color: $color-text-contrast;
	}
	.time-wrapper {
		color: $color-text;
		.time-wrapper {
			.time-in-hours {
				.is-running-icon {
					background-image: url("../../../Components/assets/svg/task-icon-running-time-dark-purple.svg");
				}
			}
		}
	}

	.time-entry-comment {
		color: $color-text-contrast;
	}
}
