@import "../../../GlobalUtils/global-utils.scss";

.edit-time-modal {
	background: $color-mid-purple;
	height: calc(100vh - 80px);
	width: calc(100vw - 30px);
	max-width: 1080px;
	max-height: 1080px;
	overflow: hidden;
	border-radius: 4px;

	color: white;

	.separator {
		@include font-size(11);
		padding: 0.5px 5px;
		background-color: lightgrey;
		opacity: 50%;
		//background-color: $color-text-contrast;
		// position: absolute;
		left: 50%;
		top: 50%;
		width: 100%;
		@include transform(translate(-50%, -50%));
	}

	.edit-in-timeline {
		color: white;
		background-color: grey;
	}

	.am-pm-toggle {
		color: white;
	}

	.save-time {
		background-color: green;

		&:disabled {
			background-color: red;
		}
	}

	.cancel {
		color: white;
		background-color: grey;
	}
}
