.rounding-settings-screen {
	grid-row-start: 2;
	grid-row-end: 4;
	grid-column-start: 1;
	grid-column-end: 3;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rounding-settings-screen .header {
	position: relative;
	padding: 80px 0;
	font-size: calc(40px + 3vmin);
	background-color: #282c34;
	color: white;
}

.rounding-settings-screen .rounding-settings-form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}

.rounding-settings-screen .rounding-settings-form .username-text-box {
	padding: 10px;
	margin: 10px;
	margin-left: 50px;
	margin-right: 50px;
}

.rounding-settings-screen .rounding-settings-form .password-text-box {
	padding: 10px;
	margin: 10px;
	margin-left: 50px;
	margin-right: 50px;
}

.rounding-settings-screen .rounding-settings-form .rounding-settings-close {
	left: 0;
	font-size: 18pt;
	color: white;
}

.rounding-settings-screen .rounding-settings-form .rounding-settings-button {
	margin-top: 10px;
}

.rounding-settings-screen .rounding-settings-form .failed-rounding-settings-label {
	color: red;
	padding: 10px;
}

.rounding-settings-screen .rounding-settings-form .cheaty-boi {
	padding: 0;
	margin-left: 30px;
	margin-top: 10px;
	width: 80px;
	height: 30px;
	background-color: lightgrey;
}

.rounding-settings-close {
	position: fixed;
	left: 20px;
	top: 20px;
	color: white;
	font-size: 20pt;
}
.integration-settings-container {
	top: 30%;
	position: absolute;
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-ms-flex-align: center;
	-webkit-align-items: center;
	-webkit-box-align: center;

	align-items: center;

	.integration-select-button {
		display: flex;
		position: relative;
		width: 150px;
		height: 100px;
		border-radius: 20px;
		margin: 10px;
		background-color: white;
		color: black;
	}
}

.integration-fields-container {
	top: 50%;
	position: absolute;
	color: white;
}
