.date-separator-wrapper,
.group-start-time-wrapper {
	display: block;
	position: relative;
	width: 100%;
	height: 1px;
	margin: 30px auto 20px auto;
	background-color: rgba($color-text, 0.15);
	flex: 0 0 1;
	.date-separator,
	.group-start-time {
		@include font-size(16);
		padding: 2px 8px;
		color: $color-white-60;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		line-height: 1;

		&:before,
		&:after {
			background-color: #333;
			content: "";
			display: inline-block;
			height: 1px;
			position: relative;
			vertical-align: middle;
			width: 45%;
		}
		&:before {
			right: 0.5em;
			margin-left: -50%;
		}
		&:after {
			left: 0.5em;
			margin-right: -50%;
		}
	}
}

// .group-start-time-wrapper {
//   display: block;
//   position: relative;
//   width: 100%;
//   height: 1px;
//   margin: 30px auto 15px auto;
//   background-color: rgba($color-text, 0.15);
//   flex: 0 0 100%;
//   height: 1px;
//   .group-start-time {
//     @include font-size(11);
//     color: rgba($color-text, 0.4);
//     padding: 2px 5px;
//     background-color: $color-text-contrast;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     @include transform(translate(-50%, -50%));
//   }
// }
