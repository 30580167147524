@import "../../../GlobalUtils/global-utils.scss";

.add-time-entry-modal {
	background: $color-mid-purple;
	height: calc(100vh - 80px);
	width: calc(100vw - 30px);
	max-width: 548px;
	overflow: hidden;
	border-radius: 4px;
	background-color: transparent;
	color: white;

	.newTimeEntryContainer {
		padding: 22px 30px;
		display: flex;
		flex-direction: column;

		font-family: $font-display;
		.Todays-Date {
			@include font-size(16);
		}
		.add-time-entry-label {
			padding-top: 17px;
			@include font-size(24);
		}
		.set-day-time {
			.TimeInfo {
				display: flex;
				flex-direction: row;
			}
			.field {
				padding: 10px 0;
				display: flex;
				flex-direction: column;
				label {
					@include font-size(16);
					padding: 5px 0;
				}

				input,
				textarea {
					min-height: 50px;
					border: none;
					outline: none;
					background-color: $color-black-20;
					color: $color-primary-contrast;
				}
				input {
					padding: 5px 10px;
					min-width: 170px;
				}
				textarea {
					resize: none;
					padding: 12px 20px;
				}
				.starting-hour-input {
					margin-right: 20px;
				}
				.ending-hour-input {
					margin-right: 17px;
				}

				.duration {
					width: 111px;
				}
			}
		}
		.add-time-entry-label {
			left: 55px;
			top: 30px;
		}
		position: relative;

		min-height: 455px;

		background-color: $color-primary-contrast-dark;

		.TimeEntryRow {
			display: flex;
			flex-direction: row;
		}

		.link-to-task {
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.edit-in-timeline {
			color: white;
			background-color: grey;
		}

		.am-pm-toggle {
			color: white;
		}

		.AddCancelButtons {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			gap: 10px;
			button {
				padding: 5px 20px;
			}
			.cancel {
				.crossIcon {
					padding: 0 10px 0 0;
					color: #32d2d7;
				}
				@include font-size(18);
				padding: 10px 20px;
				border-radius: 25px;
				border: solid 1px #32d2d7;
				color: white;
			}

			.add-time {
				.tickIcon {
					color: white;
					padding: 0 10px 0 0;
				}
				@include font-size(18);
				padding: 10px 20px;
				border-radius: 25px;
				color: $color-mid-purple;
				background-color: #32d2d7;
			}
		}
	}
}
