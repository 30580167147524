.toggle-wrapper {
	position: relative;
	height: 40px;
	z-index: 1;

	&.light-theme {
		.toggle-background {
			background-color: rgba($color-primary, 0.2);
		}
	}

	&.dark-theme {
		.toggle-background {
			background-color: rgba($color-primary, 0.4);
		}
	}

	&.toggle-option-1-active {
		.active-indicator {
			animation: toggle-anim-1 0.3s 1;
		}
	}
	&.toggle-option-2-active {
		.active-indicator {
			animation: toggle-anim-2 0.3s 1;
		}
	}
	&.toggle-option-3-active {
		.active-indicator {
			animation: toggle-anim-3 0.3s 1;
		}
	}

	.active-indicator {
		top: 0px;
		display: block;
		position: relative;
		border-radius: 40px;
		background-color: $color-primary;
		z-index: 2;
		transition: transform 0.3s, left 0.3s;
	}
	.toggle-background {
		display: block;
		position: absolute;
		width: 100%;
		top: 5px;
		z-index: 1;
		border-radius: 30px;
	}

	button {
		position: absolute;
		display: block;
		z-index: 3;
		top: 0;
		background-color: transparent;
		border: 0;

		&.toggle-option {
			top: 0;
			background-size: 16px;
			@extend %single-icon;
		}
		&.disabled,
		&:disabled {
			opacity: 1;
		}
	}

	/* 
  SMALL TOGGLE
  */
	&.sz-sm {
		height: 40px;

		&.toggle-2-state {
			width: 80px;
		}
		&.toggle-3-state {
			width: 120px;
		}

		&.toggle-option-1-active {
			.active-indicator {
				left: 0;
			}
		}
		&.toggle-option-2-active {
			.active-indicator {
				left: 40px;
			}
		}
		&.toggle-option-3-active {
			.active-indicator {
				left: 80px;
			}
		}

		button {
			width: 40px;
			height: 40px;

			&.toggle-option {
				background-size: 16px;
				&.toggle-option-1 {
					left: 0;
				}
				&.toggle-option-2 {
					left: 40px;
				}
				&.toggle-option-4 {
					left: 80px;
				}
			}

			&.active {
				background-size: 20px;
			}
			@include interactive-states() {
				background-size: 20px;
			}
		}

		.active-indicator {
			width: 40px;
			height: 40px;
		}

		.toggle-background {
			height: 30px;
		}
	}

	/* 
  MEDIUM TOGGLE 
  */
	&.sz-md {
		height: 50px;

		&.toggle-2-state {
			width: 100px;
		}
		&.toggle-3-state {
			width: 150px;
		}

		&.toggle-option-1-active {
			.active-indicator {
				left: 0;
			}
		}
		&.toggle-option-2-active {
			.active-indicator {
				left: 50px;
			}
		}
		&.toggle-option-3-active {
			.active-indicator {
				left: 100px;
			}
		}

		button {
			width: 50px;
			height: 50px;

			&.toggle-option {
				background-size: 18px;
				&.toggle-option-1 {
					left: 0;
				}
				&.toggle-option-2 {
					left: 50px;
				}
				&.toggle-option-4 {
					left: 100px;
				}
			}

			&.active {
				background-size: 24px;
			}
			@include interactive-states() {
				background-size: 24px;
			}
		}

		.active-indicator {
			width: 50px;
			height: 50px;
		}
		.toggle-background {
			height: 40px;
		}
	}

	/* 
  LARGE TOGGLE 
  */

	&.sz-lg {
		height: 60px;
		&.toggle-2-state {
			width: 120px;
		}
		&.toggle-3-state {
			width: 180px;
		}

		&.toggle-option-1-active {
			.active-indicator {
				left: 0;
			}
		}
		&.toggle-option-2-active {
			.active-indicator {
				left: 60px;
			}
		}
		&.toggle-option-3-active {
			.active-indicator {
				left: 120px;
			}
		}

		button {
			width: 60px;
			height: 60px;

			&.toggle-option {
				background-size: 24px;
				&.toggle-option-1 {
					left: 0;
				}
				&.toggle-option-2 {
					left: 60px;
				}
				&.toggle-option-4 {
					left: 120px;
				}
			}

			&.active {
				background-size: 30px;
			}
			@include interactive-states() {
				background-size: 30px;
			}
		}

		.active-indicator {
			width: 60px;
			height: 60px;
		}
		.toggle-background {
			height: 50px;
		}
	}
}

/* Animations duplicated so that animation triggers each time active 'toggle-option' class name changes */

@include keyframes(toggle-anim-1) {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	48% {
		-webkit-transform: scaleY(0.5) scaleX(1.25);
		transform: scaleY(0.5) scaleX(1.25);
	}

	73% {
		-webkit-transform: scaleY(1.05) scaleX(1.05);
		transform: scaleY(1.05) scaleX(1.05);
	}

	80% {
		-webkit-transform: scaleY(0.98) scaleX(0.98);
		transform: scaleY(0.98) scaleX(0.98);
	}

	97%,
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@include keyframes(toggle-anim-2) {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	48% {
		-webkit-transform: scaleY(0.5) scaleX(1.25);
		transform: scaleY(0.5) scaleX(1.25);
	}

	73% {
		-webkit-transform: scaleY(1.05) scaleX(1.05);
		transform: scaleY(1.05) scaleX(1.05);
	}

	80% {
		-webkit-transform: scaleY(0.98) scaleX(0.98);
		transform: scaleY(0.98) scaleX(0.98);
	}

	97%,
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@include keyframes(toggle-anim-3) {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	48% {
		-webkit-transform: scaleY(0.5) scaleX(1.25);
		transform: scaleY(0.5) scaleX(1.25);
	}

	73% {
		-webkit-transform: scaleY(1.05) scaleX(1.05);
		transform: scaleY(1.05) scaleX(1.05);
	}

	80% {
		-webkit-transform: scaleY(0.98) scaleX(0.98);
		transform: scaleY(0.98) scaleX(0.98);
	}

	97%,
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
