input,
textarea {
	font: "Dosis", sans-serif; /* Overrise default browser styles */
	font-family: "Dosis", sans-serif;
	@include font-size(18);
}

.checkbox-container {
	position: relative;
	width: 22px;
	height: 22px;
	flex: 0 0 22px;
	margin-top: 4px;
	input[type="checkbox"],
	input[type="radio"] {
		position: absolute;
		left: 0;
		top: 0;
		min-width: 1em;
		width: 100%;
		height: 100%;
		z-index: 2;
		opacity: 0;
		margin: 0;
		padding: 0;
		cursor: pointer;

		&:checked {
			~ label {
				&::after {
					opacity: 1;
				}
			}
		}
		&:disabled {
			cursor: auto;
			@include interactive-states() {
				~ label {
					opacity: 0.3;
					&::before,
					&::after {
						border: 1px solid rgba($color-text, 0.75);
						background: transparent;
					}
				}
			}
			~ label {
				opacity: 0.3;
				&::before,
				&::after {
					border: 1px solid rgba($color-text, 0.75);
					background: transparent;
				}
			}
		}
	}
	label {
		width: 100%;
		height: 100%;
		position: relative;
		display: block;
		margin: 0;
		font-size: 0;
		overflow: hidden;
		transition: all 0.3s;
		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			background-color: transparent;
			left: 0;
			transition: all 0.3s;
		}
		&::before {
			display: block;
			border: 1px solid rgba($color-text, 0.75);
			border-radius: 4px;
			z-index: 0;
		}
		&::after {
			opacity: 0;
			background-position: center center;
			background-size: 60%;
			background-repeat: no-repeat;
		}
	}
	&.primary {
		input[type="checkbox"],
		input[type="radio"] {
			&:checked {
				~ label {
					&::before {
						border: 1px solid $color-primary;
						background-color: $color-primary;
					}
				}

				@include interactive-states() {
					~ label {
						&::before {
							border: 1px solid rgba($color-text, 0.5);
						}
					}
				}
			}
			&:disabled {
				~ label {
					&::before,
					&::after {
						border: 1px solid rgba($color-text, 0.75);
						background: transparent;
					}
				}
			}
			@include interactive-states() {
				~ label {
					&::before {
						border: 1px solid $color-primary;
					}
				}
				&:disabled {
					~ label {
						&::before,
						&::after {
							border: 1px solid rgba($color-text, 0.75);
							background: transparent;
						}
					}
				}
			}
		}
		label {
			&::after {
				background-image: url("../Components/assets/svg/tick-white-outline.svg");
			}
		}
	}
}
