@import "../../../GlobalUtils/global-utils.scss";

.group-task-modal {
	.header-section {
		width: 100%;
		position: relative;
		padding: 15px;
		@include flex-display();
		@include flex-direction(row);
		@include flex-align-items(center);
		@include flex-justify(flex-start);
		box-sizing: border-box;
		background-color: $color-text;
		color: $color-text-contrast;
		box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.15);
		@include border-radius(15px 15px 0 0);
		z-index: 2;
		margin-top: -1px;

		.group-indicator {
			width: 20px;
			height: 20px;
			@include border-radius(50%);
			border: 2px solid $color-text-contrast;
			margin-right: 15px;
			display: block;
		}

		.name {
			@include font-size(22);
			width: auto;
			display: block;
		}
	}

	.modal-content-wrapper {
		@include flex-display();
		@include flex-direction(row);
		flex-wrap: wrap;
		margin-top: -1px;
		@include media-min-w(640px) {
			flex-wrap: nowrap;
			//max-height: 400px;
		}
		flex: 1 0 auto;

		.group-section {
			display: block;
			position: relative;
			width: 50%;
			flex: 1 0 auto;
			padding: 25px;
			box-sizing: border-box;
			border-right: 1px solid $color-text;
			background-color: $color-text;
			color: $color-text-contrast;
			@include border-radius(0 0 0 15px);

			.no-group {
				background-color: rgba($color-text-contrast, 0.1);
				padding: 15px;
				@include border-radius(6px);
				margin: 0;
				@include font-size(18);
				text-align: center;
			}

			.group-info {
				height: 100%;
				overflow-y: auto;
				overflow-x: hidden;
				padding-left: 60px;
				padding-right: 20px;

				.group-info-header {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 20px;
					.group-total-time {
						position: relative;
						.icon {
							position: absolute;
							left: -56px;
							top: 12px;
							width: 35px;
							height: 35px;
							background-image: url("../../assets/svg/task-icon-running-time-white.svg");
							background-size: contain;
							background-position: center;
							background-repeat: no-repeat;
							opacity: 0.3;
						}
						h2 {
							&.title {
								// @include font-size(16);
								font-weight: 900;
								text-transform: uppercase;
								line-height: 1;
							}
						}
						.total-time-value {
							display: block;
							font-weight: 900;
							@include font-size(26);
							line-height: 1;
							.time-unit-indicator {
								@include font-size(18);
								margin-left: 2px;
							}
						}
					}
				}
				.group-summary {
					position: relative;
				}

				.halo-wrapper {
					position: absolute;
					display: block;
					width: 45px;
					height: calc(100% - 85px);
					bottom: 0;
					left: 25px;

					.current-time,
					.start-time {
						display: block;
						position: absolute;
						font-size: 9pt;
						width: 100%;
						text-align: center;
					}
					.current-time {
						top: 0;
					}

					.start-time {
						bottom: 20px;
					}
				}

				button {
					background-size: 20px;
					&.task-sync {
						background-image: url("../../assets/svg/sync-dark-purple.svg");
					}
					&.task-sync {
						border-color: $color-tertiary;
						background-color: $color-tertiary;
						@include interactive-states() {
							border-color: darken($color-tertiary, 10%);
							background-color: darken($color-tertiary, 10%);
						}
						&.disabled,
						&:disabled {
							border-color: $color-primary;
							background-color: $color-primary;
							opacity: 0.3;
							@include interactive-states() {
								border-color: $color-primary;
								background-color: $color-primary;
							}
						}
					}
				}
			}
		}

		.task-section {
			display: block;
			position: relative;
			flex: 1 0 auto;
			width: 50%;
			padding: 25px;
			box-sizing: border-box;
			background-color: $color-primary-xx-light;
			@include border-radius(0 0 15px 0);

			.task-info {
				.project-name {
					position: relative;
					left: 10%;
					top: 5%;
					font-size: 18pt;
				}

				.task-status {
					position: relative;
					right: 10%;
					top: 7%;
					width: 60px;
					height: 20px;
					background-color: lightgreen;
				}

				.task-description {
					position: relative;
					top: 15%;
					left: 10%;
					height: 20%;
					width: 80%;
					font-size: 12pt;
					border: 1px solid;
				}

				.task-comments {
					position: relative;
					top: 40%;
					left: 10%;
					height: 45%;
					width: 80%;
					font-size: 12pt;
					border: 1px solid;
				}

				.add-comment-button {
					position: relative;
					right: 20px;
					bottom: 20px;
					width: 50px;
					height: 30px;
					background-color: lightgrey;
				}
			}
		}
	}
}
