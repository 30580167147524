/*
--
Theme - Variables
--
*/

$color-text: #3c1e32;
$color-text-contrast: #fff;
$color-mid-purple: #522a46;

$color-text-light: #9d8e98;

$color-primary: #32d2d7;
$color-primary-contrast: #fff;
$color-primary-contrast-dark: #3c1e32;

$color-primary-light: #ccf4f5;
$color-primary-x-light: #effbfc;
$color-primary-xx-light: #f5fdfd;

$color-secondary: #faa05f;
$color-secondary-contrast: #fff;
$color-secondary-contrast-dark: #3c1e32;

$color-tertiary: #2be08d;
$color-tertiary-contrast: #fff;
$color-tertiary-contrast-dark: #3c1e32;

$color-black-20: #00000033;
$color-white-50: #ffffff80;
$color-white-60: #ffffff99;

$font-body: "Rubik", sans-serif;
$font-display: "Dosis", sans-serif;

$aside-width-mobile: 100%;
$aside-width-tablet: 350px;
$aside-width-desktop: 500px;
