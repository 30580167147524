@import "../../GlobalUtils/global-utils.scss";

.search-list-header {
	display: block;
	padding: 15px;
	@media (min-width: 1520px) and (min-height: 768px) {
		padding: 25px;
	}
	box-shadow: 0 5px 10px 0px rgba($color-text, 0.2);
	position: relative;
	z-index: 98;
	background-color: $color-primary-x-light;
	min-height: 0; /* For firefox */
	@include transition(height 0.3s);
	flex: 0 0 auto;

	.task-view-header-container {
		@include flex-display();
		@include flex-direction(row);
		@include flex-align-items(center);
		margin-bottom: 15px;
		h1 {
			@include font-size(30);
			color: $color-primary;
			text-transform: uppercase;
			margin: 10px 0;
			font-weight: 900;
			letter-spacing: 1px;
			width: calc(100% - 60px);
			display: inline-block;
		}
	}

	&.mode-search-controls-closed {
		.task-view-header-container {
			margin-bottom: 0px;
		}
	}
}

#search-list-wrapper {
	position: relative;
	width: $aside-width-mobile;
	//   height: 100vh;
	background-color: $color-mid-purple;
	overflow: hidden;
	//   position: fixed;
	//   top: 0;
	//   left: 0;
	//   bottom: 0;
	//   right: 0;
	//   z-index: 101;
	width: 100%;
	height: 100%;
	@include media-min-w(768px) {
		// left: auto;
		// width: $aside-width-tablet;
	}
	@include media-min-w(1080px) {
		// width: $aside-width-desktop;
	}

	#search-list-container {
		position: relative;
		@include flex-display();
		@include flex-direction(column);
		z-index: 1;
		min-height: 0; /* For firefox */
		overflow: hidden;
		height: 100%;
		width: 100%;
	}

	#search-list-header {
		width: 100%;
		height: 100px;
		overflow: hidden;
		box-shadow: 0 5px 10px 0px rgba(60, 30, 50, 0.2);
		display: block;
		position: relative;
		background-color: $color-primary-x-light;
	}
}

.task-list-wrapper {
	width: 100%;
	@include flex-display();
	@include flex-direction(column);
	min-height: 0; /* For firefox */
	flex: 1 0 auto;
	height: calc(100% - 82px);
	overflow: hidden;

	@include transition(height 0.3s);
	.task-list-container {
		@include flex-display();
		@include flex-direction(column);
		@include flex-grow(1);
		min-height: 0; /* For firefox */
		padding: 15px;
		overflow-y: auto;
		position: relative;

		&::-webkit-scrollbar {
			width: 15px;
			background: rgba($color-text-contrast, 0.15);
		}

		&::-webkit-scrollbar-thumb {
			background: rgba($color-text-contrast, 0.4);
		}
	}
}
