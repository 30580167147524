@import "../../../../GlobalUtils/global-utils.scss";

.task-header-wrapper {
	.task-header-container {
		display: flex;
		flex: 1 0 auto;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 12px 20px;
		overflow: hidden;
		.task-title {
			flex: 1 0 auto;
			height: 100%;
			max-width: calc(100% - 50px);
			h2.task-project {
				font-weight: 400;
				@include font-size(25);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin-top: 2px;
			}
			h3.task-name {
				font-weight: 900;
				@include font-size(25);
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				color: $color-text-contrast;
			}
			.task-name-wrapper {
				display: flex;
			}
		}
		button.unlink-button {
			display: block;
			box-sizing: border-box;
			width: 50px;
			height: 50px;
			margin-left: 15px;
			flex: 0 0 auto;
			border-radius: 50%;
			border: 1px solid $color-primary;
			background-image: url("../../../assets/svg/link-white.svg");
			padding: 0;
			@include transition(all 0.3s);
			@include interactive-states() {
				background-color: $color-primary;
				background-image: url("../../../assets/svg/link-dark-purple.svg");
			}
		}
	}
}

.task-details-wrapper {
	.task-details-container {
		padding: 0 20px 30px 20px;
	}
}

.external-link {
	//@include font-size(20);
	padding: 0 10px 10px 10px;

	color: $color-text;
	:visited {
		color: $color-text;
	}

	&.inverse-color {
		color: $color-text-contrast;

		:visited {
			color: $color-text-contrast;
		}
	}
}
