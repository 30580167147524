.spinner {
	margin-left: 20px;
	width: 40px;
	height: 40px;
	border-top-color: $color-text;
	border-top-style: solid;
	border-radius: 50%;
	animation: spin 2s infinite;
}

@-moz-keyframes spin {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
