@import "../../../GlobalUtils/global-utils.scss";

.orderby-group-wrapper {
	margin-top: 20px;

	.orderby-group-header-wrapper {
		display: block;
		position: relative;
	}

	.group-actions-wrapper {
		margin-bottom: 40px;
	}

	button {
		&.expand-time-entries {
			display: block;
			position: absolute;
			height: 40px;
			width: 40px;
			background-image: url("../../assets/svg/chevron-blue-outline.svg");
			width: 40px;
			background-size: 22px;
			//   @extend %single-icon;
			background-repeat: no-repeat;
			background-position: center;

			padding: 0;
			right: 0;
			bottom: 0;
			z-index: 2;

			&.collapsed {
				transform: rotate(180deg);
			}

			&.expanded {
				transform: rotate(0deg);
			}
			&:hover {
				background-position: center 5px;
				&.expanded {
					//   background-position: center 0px;
				}
			}
			&:active,
			&:focus,
			&:active:focus {
				background-size: 25px;
			}
		}
	}
}
