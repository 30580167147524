@import "../../../GlobalUtils/global-utils.scss";

.menu-wrapper {
	z-index: 99;
	background-color: rgba($color-text, 0.92);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: auto;
	.menu-container {
		padding: 75px 30px;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100%;
	}
}

// #dashboard-wrapper .menu {
//   position: absolute;
//   top: 0;
//   left: 320px;
//   width: 300px;
//   height: 100%;
//   padding-top: 20px;
// }

.playhead-wrapper .menu {
	position: absolute;
	bottom: 65px;
	left: 0;
	width: 500px;
	height: 300px;
	border-radius: 2%;
	padding-top: 5px;
}
